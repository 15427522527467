// assign an agency to an award
// used on award details page
import { Component, OnInit, ViewChild, ChangeDetectionStrategy, Input } from '@angular/core';
import { StringUtil } from '../shared/string-util';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
// import { ActivitiesService } from '../activities/activities.service';
import { AwardsService } from '../awards/awards.service';
import { environment } from '../../environments/environment';
// import { StudentsService } from '../students/students.service';
import { AgenciesService } from '../agencies/agencies.service';
import { Components } from '@ionic/core';
import * as moment from 'moment';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { LoadingController } from '@ionic/angular';

@Component({
  changeDetection: ChangeDetectionStrategy.Default,
  selector: 'add-agency',
  templateUrl: './add-agency.component.html',
  styleUrls: ['./add-agency.component.scss'],
})
export class AddAgencyComponent implements OnInit {

  indicator: HTMLIonLoadingElement = null;
  requestsQueue: Array<any> = [];

  agencyAndAmount: string;
  contractAmountCalc: any;
  enteredRate: void;
  // award: any;

  constructor(
    private awardsService: AwardsService,
    public agenciesService: AgenciesService,
    public formBuilder: FormBuilder,
    private loadingController: LoadingController
  ) {
    this.agenciesListForm = formBuilder.group({
      agencyId: ['', [Validators.required]]
    });
  }
  @Input() award: any;
  public agenciesListForm: FormGroup;
  @Input() modal: Components.IonModal;
  @Input() primaryAgency: any;
  @Input() primaryAgencyDetail: any;
  agenciesList: any;
  loading = false;
  agencyAward: any = {};
  queryText = '';
  rows: any = [];
  filteredRows: any = [];
  selected = [];
  columnMode = ColumnMode.force;
  selectionType = SelectionType.single;
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  showAgencyRate: boolean;

  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  onSelect(row) {
    console.log('Select Event ', row);
    row = row.selected[0];
    console.log(row);
    this.agencyAward.agencyId = row.id;
    this.agencyAward.agencyType = row.type;
    this.agencyAward.enrollmentDate = new Date();
    this.agencyAward.enrolledSchoolCount = this.award.agencies.enrolledSchoolCount;
    this.agencyAward.totalFundAmt = '10';
    this.agencyAward.cy2019 = '10%';
    this.agencyAward.cyCurrent = '10%';
    console.log(this.agencyAward);
    this.awardsService.assignAgency(this.agencyAward, this.modal).add(() => {
      this.modal.dismiss();
    });
  }

  ngOnInit() {
    this.agenciesListForm.valueChanges.subscribe(change => {
      if (this.agenciesListForm.dirty) {
        this.modal.backdropDismiss = false;
      }
    });
    console.log(this.primaryAgencyDetail, '==================detail');
    // this.showLoader();
    // this.agenciesService.getUnassignedAgencies(this.award.award.id).subscribe((data: any) => {
    //   this.primaryAgencyDetail = {
    //     ...this.primaryAgencyDetail,
    //     type: 2
    //   };
    //   console.log('fetchData(): data from agencies service:', data);
    //   const activeList = data.filter(x => x.isActive);
    //   this.agenciesList = [
    //     ...activeList,
    //     this.primaryAgencyDetail
    //   ];
    //   this.hideLoader();
    //   console.log(this.agenciesList, 'agency lists -------------------------------');
    // });
    console.log('test');
  }

  selectionChanged($event) {
    document.getElementById('dropdowndiv').style.height = '50px';
    if ($event[0].data.id && $event[0].data.type === 2) {
      this.showAgencyRate = true;
      const amount = this.formatter.format($event[0].data.agencyRate);
      this.agencyAndAmount = $event[0].data.name + ' is ' + amount;
      this.agenciesListForm.addControl('agencyRate',
        new FormControl('', [Validators.required, Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]));
      this.agenciesListForm.get('agencyRate').setValue($event[0].data.agencyRate);
      this.contractAmountCalc = 'Contract Amount = ' + this.formatter.format(this.award.annualTarget * $event[0].data.agencyRate);
    } else {
      this.showAgencyRate = false;
      this.agenciesListForm.removeControl('agencyRate');
    }
  }
  onClickDropDown() {
    document.getElementById('dropdowndiv').style.height = '150px';
  }

  changeText($event) {
    this.contractAmountCalc = 'Contract Amount = ' +
      this.formatter.format((Number(this.award.award.awardAmount / this.award.stateRate) * Number($event.detail.value)));
  }

  submitAgency() {
    console.log(this.agenciesListForm.value);
    console.log(this.modal);
    console.log(this.agencyAward);
    console.log(this.award);
    console.log(this.rows);
    this.agencyAward.agencyId = this.agenciesListForm.value.agencyId;
    this.agencyAward.schoolId = this.award.award.school ? this.award.award.school.id : null;
    this.agencyAward.awardId = this.award.award.id;
    this.agencyAward.contractId = this.award.award.contractId;
    console.log('agencyrate ' + this.agenciesListForm.value.agencyRate);
    if (this.agenciesListForm.value.agencyRate === undefined || this.agenciesListForm.value.agencyRate === '' ||
      this.agenciesListForm.value.agencyRate === null) {
      this.agencyAward.agencyRate = null;
    } else {
      this.agencyAward.agencyRate = this.agenciesListForm.value.agencyRate;
    }
    this.agencyAward.isActive = true;
    console.log(this.agencyAward);
    this.awardsService.assignAgency(this.agencyAward, this.modal);
  }

  showLoader() {
    if (this.indicator == null) {
      this.indicator = {} as HTMLIonLoadingElement;
      if (this.requestsQueue.length == 0) {
        this.loadingController
          .create({
            message: 'Please wait...',
            duration: 1000,
          })
          .then((res) => {
            this.indicator = res;
            res.present();

            // res.onDidDismiss().then((dis) => {
            // console.log("Loading dismissed!");
            // });
          });
      }
    }
    // this.requestsQueue.push({});
  }

  hideLoader() {
    this.requestsQueue.pop();
    if (this.requestsQueue.length == 0) {
      if (this.indicator != null || this.indicator != undefined) {
        if (this.indicator.dismiss) { this.indicator.dismiss(); }
      }
      this.indicator = null;
    }
  }
}
