import { ModalAlertComponent, ModalComponent } from './../modal-alert/modal-alert.component';
import { UserData } from './../users/user-data';
import { CalendarService } from './../core/services/calendar.service';
import { TabsService } from './../components/tabs.service';
import { AlertController, Config, ModalController, PopoverController, ToastController } from '@ionic/angular';
import { StringUtil } from '../shared/string-util';
import { CalendarEditorComponent } from './../calendar-editor/calendar-editor.component';
import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  AfterContentInit,
  OnDestroy,
  HostListener,
} from '@angular/core';
import { DateRange } from '../calendar-editor/DateRange';
import { CalendarEvent } from '../calendar-editor/CalendarEvent';
import { MomentLongDateFormat } from 'moment';
import * as m from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { DatatableComponent, ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { map } from 'rxjs/operators';
import { CalendarPeriod } from './CalendarPeriod';
import { ReportsService } from '../reports/reports.service';
import { BreadCrumbService } from '../components/bread-crumb/bread-crumb.service';
import { StaffService } from '../staff/staff.service';
import { PopCertificationTypesComponent } from '../pop-certification-types/pop-certification-types.component';
import { UpdateCertificationTypeComponent } from '../update-certification-type/update-certification-type.component';
import * as moment from 'moment';
import { UpdateCodeComponent } from '../update-enrichmentCode/update-code.component';
import { PopEnrichmentCodesComponent } from '../pop-enrichmentCodes/pop-enrichment-codes.component';
import { NgxSelectOption } from 'ngx-select-ex';
import { SubmitAttendanceConfirmationComponent } from '../submit-attendance-confirmation/submit-attendance-confirmation.component';
import { StudentsService } from '../students/students.service';
import _ from 'lodash';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SchoolDetailsService } from '../school-details/school-details.service';
import { SchoolsService } from '../schools/schools.service';
import { AppComponent } from '../app.component';
import { PopRetroActionsComponent } from '../pop-retro-actions/pop-retro-actions.component';
import { of } from 'rxjs';


@Component({
  selector: 'master-calendar-page',
  templateUrl: './master-calendar-page.component.html',
  styleUrls: ['./master-calendar-page.component.scss'],
})
export class MasterCalendarPageComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  @ViewChild(DatatableComponent, { static: false }) table2: DatatableComponent;
  moment = m;
  calendars = [];
  selected = [];
  fiscalYearList = [];
  calendarYearList = [];
  masterCalendarList = [];
  currentMasterCalendar: any;
  currentMasterCalendarCode: string;
  selectedMasterCalendarCode: string;
  types = [];
  filteredTypes = [];
  queryText = '';
  queryTextCert = '';
  exceptions: any = [];
  studentExceptions: any = [];
  type = 'Core';
  filteredCalendars = [];
  initItem = { 'id': 0, 'description': 'All' };
  innerWidthGreater: boolean;
  filterColumnsCert = ['name', 'code', 'description', 'effectiveDate', 'certificateExpDays', 'active'];
  enrichmentCodes: any;
  loading = false;
  columnMode = ColumnMode.force;
  filteredCodes: any;
  defaultSort = [{ prop: 'code', dir: 'asc' }];
  columns = [
    { name: 'Name', prop: 'code' },
    { name: 'Description', prop: 'description' },
    { name: 'Active', prop: 'active' },
  ];
  filterColumns = ['code', 'description', 'active'];
  currentFiscalYear: any;
  currentCalendarYear: any;


  list: 'list';
  ios: boolean;


  constructor(
    private modalController: ModalController,
    private router: Router,
    private route: ActivatedRoute,
    public tc: TabsService,
    private calendarService: CalendarService,
    private staffService: StaffService,
    private reportsService: ReportsService,
    private popoverController: PopoverController,
    private activatedRoute: ActivatedRoute,
    public ud: UserData,
    private bc: BreadCrumbService,
    public alertController: AlertController,
    public toastController: ToastController,
    private studentsService: StudentsService,
    private schoolDetailsService: SchoolDetailsService,
    public config: Config,
    private schoolsService: SchoolsService,
    private appComponent: AppComponent,
    private formBuilder: FormBuilder
  ) {
    this.attendanceApproval = formBuilder.group({
      students: new FormArray([]),
      approverNotes: ['']
    });
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidthGreater = window.innerWidth <= 728;
  }
  ngOnInit(): void {
    this.innerWidthGreater = window.innerWidth <= 728;
    this.fetchFiscalYears();
    this.fetchCalendarYears();
    this.fetchMasterCalendars();
    this.fetchCodes();
    if (this.ud.hasRoles([1, 4])) {
      this.fetchCertificationTypes();
    }
    this.bc.breadCrumb$.next('Calendar');
      this.type = 'details';
      this.list = 'list';
      this.ios = this.config.get('mode') === 'ios';
      this.schoolId = Number(this.route.snapshot.params['id']);

      // blocks roles 6 - 8 from seeing school details
      if (this.profile.roles.length > 0 &&
        (this.profile.roles.includes(6) || this.profile.roles.includes(7) || this.profile.roles.includes(8))) {
        this.schoolsService.getSchoolsByAgency(this.profile.agency).subscribe((data: any) => {
          const allowed = data.some(x => x.id === Number(this.schoolId));
          if (!allowed) {
            this.router.navigateByUrl('/unauthorized');
            event.stopPropagation();
          }
        });
      }

      let tabSelected = this.route.snapshot.queryParams['tabSelected'];

      if (tabSelected) {
        this.tc.tabChannel = tabSelected;
      }

      if (this.route.snapshot.params['schoolId']) {
        this.schoolId = Number(this.route.snapshot.params['schoolId']);
      }

      this.fetchMasterCalendars();
      // this.fetchActivityData();
      this.fetchAttendanceRetro(this.currentMasterCalendar);
      this.fetchAttendanceRetroList();
      this.calendarService
        .calendarsBySchool(this.schoolId)
        .pipe(
          map((calendars) => {

            const hasCalendar = calendars != null && calendars.length > 0;

            if (hasCalendar) {
              return this.parseCalendar(calendars);
            } else {
              // Remove calendar option from page tabs
              this.appComponent.removeTabOption(this.route, "calendar");
            }
          })
        )
        .subscribe((calendars) => {
          if (calendars) {
            this.calendars = calendars.reverse();
          }
        });

  }


  ngAfterViewInit() {
    console.log('ngAfterViewInit() Start ->>>>>>>>>XXXXXXX');
    this.loadCalendars();
    this.checkPermissions();

    const typeParam = this.route.snapshot.queryParams['type'];
    if (typeParam) {
      this.type = typeParam === '2' ? 'Supplemental' : 'Core';
    }
  }
  getCellClass({ row, column, value }) {
    if (column.prop === 'name' || column.prop === 'description') {
      return ' left-aligned-col';
    } else if (column.prop === 'agencyId' || column.prop === 'enrolledSchoolCount') {
      return ' right-aligned-col';
    } else {
      return '';
    }
  }



  fetchCertificationTypes() {
    this.staffService.getTypes().subscribe((data: any) => {
      console.log('fetchCertificationTypes(): data from service:', data);
      // this.loading = false;
      data.forEach(element => {
        element.effectiveDate = new Date(element.effectiveDate);
        element.effectiveDateDisp = moment(element.effectiveDate).format('MM/DD/YYYY');
        element.active = element.isActive ? 'Yes' : 'No';
      });
      console.log('fetchCertificationTypes(): done');
      this.types = data;
      this.filteredTypes = data;
    });
  }
  async createCode(update) {
    console.log(update);
    const modal = await this.modalController.create({
      component: UpdateCodeComponent,
      componentProps: { update: false }
    });
    modal.onDidDismiss().then(() => {
      this.fetchCodes();
    });
    return await modal.present();
  }
  /*async updateCode(update) {
    console.log(update);
    const modal = await this.modalController.create({
      component: UpdateCodeComponent,
      componentProps: { update: true, updateCode: update }
    });
    modal.onDidDismiss().then(() => {
      this.fetchCodes();
    });
    return await modal.present();
  }*/
  async presentPopover2(ev: any, row: any) {
    event.stopPropagation();
    const popover = await this.popoverController.create({
      component: PopEnrichmentCodesComponent,
      cssClass: 'popover-custom-css',
      event: ev,
      translucent: true,
      componentProps: { type: row, update: true },
    });
    popover.onDidDismiss().then((result) => {
      if (result.data !== 'cancel' && result.data !== undefined) {
        this.calendarService.updateCode(result.data).add(() => {
          this.fetchCodes();
        });
      }
    });
    return await popover.present();
  }
  clearEnrichSearch() {
    this.queryText = '';
    this.filteredCodes = this.enrichmentCodes;
  }

  filterRows() {
    console.log(`TODO: Filter Rows`);
  }

  filterCodeRows() {
    const safeQueryText = StringUtil.escapeSpecialCharacters(
      this.queryText.trim().toLowerCase()
    );
    this.filteredCodes = this.enrichmentCodes.filter((row: any) => {
      if (!safeQueryText) {
        return true;
      }
      for (const filterColumn of this.filterColumns) {
        const colValue = row[filterColumn];
        if (
          colValue &&
          colValue
            .toString()
            .toLowerCase()
            .match(safeQueryText)
        ) {
          return true;
        }
      }
      return false;
    });
    this.table.offset = 0;
  }
  fetchFiscalYears() {
    this.reportsService.getFiscalYears().subscribe((data: any) => {
      console.log('fetchFiscalYears(): data from fiscalYear service:', data);
      // data.unshift(this.initItem);
      this.fiscalYearList = data;

      let fiscalCalendarYear = this.currentFiscalCalendarYear();
      let currentFiscalYear = `FY${fiscalCalendarYear-1}-${fiscalCalendarYear}`;
      let defaultSelection = data.find(d => d.description === currentFiscalYear);

      if(defaultSelection != undefined){
        this.currentFiscalYear = defaultSelection.id;
        this.filterCalendarList(this.currentFiscalYear);
      }
    });
  }
  fetchMasterCalendars() {
    this.reportsService.getMasterCalendars().subscribe((data: any) => {
      console.log('fetchMasterCalendars(): data from calendarYear service:', data);
      // data.unshift(this.initItem);
      this.masterCalendarList = data;
      if(this.masterCalendarList != undefined || this.masterCalendarList == null){
        var currentActiveMasterCalendar = this.masterCalendarList.find(m => m.isCurrent);
        if(currentActiveMasterCalendar!= undefined){
          this.currentMasterCalendar = currentActiveMasterCalendar.masterCalendarName;
          this.currentMasterCalendarCode = currentActiveMasterCalendar.masterCalendarCode;
          this.selectedMasterCalendarCode = currentActiveMasterCalendar.masterCalendarCode;
        }
      }
    });
  }

  fetchCalendarYears() {
    this.reportsService.getCalendarYears().subscribe((data: any) => {
      console.log('fetchCalendarYears(): data from calendarYear service:', data);
      // data.unshift(this.initItem);
      this.calendarYearList = data;

      let fiscalCalendarYear = this.currentFiscalCalendarYear();
      let currentFiscalYear = `CY${fiscalCalendarYear}`;
      let defaultSelection = data.find(d => d.description === currentFiscalYear);

      if(defaultSelection != undefined){
        this.currentCalendarYear = defaultSelection.id;
        this.filterCalendarList(this.currentCalendarYear);
      }
    });
  }

  currentFiscalCalendarYear(){
    var today = new Date();
    var month = today.getMonth() + 1;
    var year = today.getFullYear();

    return month > 6 ? year + 1: year;
  }

  fetchCodes() {
    this.calendarService.getEnrichmentCodes().subscribe((data: any) => {
      console.log('fetchCodes(): data from calendarYear service:', data);
      data.forEach(element => {
        element.active = element.isActive ? 'Yes' : 'No';
      });
      this.enrichmentCodes = data;
      this.filteredCodes = data; // skip filtering on initial load
      // this.loading = false;
      console.log('fetchData(): done');

      // test out the loading bar (todo: remove later)
      const that = this;
      setTimeout(function () {
        that.loading = false;
      }, 1000);
    });
  }

  loadCalendars() {
    this.calendarService
      .calendars()
      .pipe(
        map((calendars) => {
          return this.parseCalendar(calendars);
        })
      )
      .subscribe((calendars) => {
        this.calendars = calendars;
        //this.filteredCalendars = calendars;
        console.log('here---', this.filteredCalendars);
        this.updateTable({ detail: { value: this.type } });
      });
    this.calendarService.exceptions().subscribe((exceptions) => {
      this.exceptions = exceptions;
    });
    if (this.ud.hasRoles([4])) {
      this.calendarService.studentAttendanceExceptions().subscribe((exceptions) => {
        this.studentExceptions = exceptions;
        this.studentExceptions = this.studentExceptions.filter(x => x.isActive);
      });
    }
  }

  checkPermissions() {
    if (this.ud.hasRoles([1, 2, 3, 4, 5])) {
      this.type = 'Core';
    } else {
      this.type = 'Supplemental';
    }
  }
  parseCalendar(calendars) {
    return calendars.map((c) => {
      c.type = c.operationalCalendarType === 1 ? 'Core' : 'Supplemental';
      return c;
    });
  }
  async removeCalendar(row: any) {
    event.stopPropagation();
    const alertModal: ModalComponent = {
      body: 'Remove calendar',
      header: 'Calendar',
      cancelText: 'cancel',
      okText: 'Yes',
      model: {}
    };
    const modal = await this.modalController.create({
      component: ModalAlertComponent,
      // backdropDismiss: false,
      componentProps: {
        props: alertModal
      }
    });
    modal.onDidDismiss().then((result) => {
      console.log(result);
      if (result.data === 'cancel' || result.role === 'backdrop') {
        return;
      } else {
        this.calendarService.removeCalendar(row).subscribe(() => {
          this.loadCalendars();
        });
      }


      /*  if (result.data !== 'cancel' || result.data !== undefined) {
          row.isActive = false;

        }*/
    });
    await modal.present();
  }

  async createCertificationType() {
    const modal = await this.modalController.create({
      component: UpdateCertificationTypeComponent,
      componentProps: {
        update: false
      }
    });
    modal.onDidDismiss().then((result) => {
      if (result.data !== 'cancel' && result.data !== undefined) {
        this.staffService.updateCertificationType(result.data, false).add(() => {
          this.fetchCertificationTypes();
        });
      }
    });
    await modal.present();
  }

  async presentPopover(ev: any, row: any) {
    event.stopPropagation();
    const popover = await this.popoverController.create({
      component: PopCertificationTypesComponent,
      cssClass: 'popover-custom-css',
      event: ev,
      translucent: true,
      componentProps: { type: row },
    });
    popover.onDidDismiss().then((result) => {
      if (result.data !== 'cancel' && result.data !== undefined) {
        this.staffService.updateCertificationType(result.data, true).add(() => {
          this.fetchCertificationTypes();
        });
      }
    });
    return await popover.present();
  }

  async presentPopover3(ev: any, row: any) {
    ev.stopPropagation();
    const popover = await this.popoverController.create({
      component: PopRetroActionsComponent,
      cssClass: 'pop-schedule-act',
      event: ev,
      translucent: true,
      componentProps: { attendanceReq: [row] },
    });
    popover.onDidDismiss()
    .then((result) => {
      if(result['data'] == 'submitted'){
        this.fetchAttendanceRetroList();
      }
    });

    return await popover.present();
  }


  clearCertSearch() {
    this.queryTextCert = '';
    this.filteredTypes = this.types;
  }

  filterCertifications() {
    const safeQueryText = StringUtil.escapeSpecialCharacters(
      this.queryTextCert.trim().toLowerCase()
    );
    // console.log('filterRows(): safeQueryText: ', safeQueryText);
    this.filteredTypes = this.types.filter((row: any) => {
      if (!safeQueryText) {
        return true;
      }
      for (const filterColumn of this.filterColumnsCert) {
        let colValue = row[filterColumn];
        if (moment.isDate(colValue)) {
          colValue = moment(colValue).format('MM/DD/YYYY');
        }
        // toString() is needed if we want to search on numbers
        if (
          colValue &&
          colValue
            .toString()
            .toLowerCase()
            .match(safeQueryText)
        ) {
          return true;
        }
      }
      return false;
    });
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  async removeStudentException(row: any) {
    event.stopPropagation();
    const alertModal: ModalComponent = {
      body: 'Remove student exception?',
      header: 'Student Attendance Dispute',
      cancelText: 'Cancel',
      okText: 'Yes',
      model: {}

    };
    const modal = await this.modalController.create({
      component: ModalAlertComponent,
      componentProps: {
        props: alertModal
      }
    });
    modal.onDidDismiss().then((result) => {
      if (result.data != 'cancel') {
        row.isActive = false;
        this.calendarService.removeStudentAttendanceException(row).subscribe(() => {
          this.loadCalendars();
        });
      }
    });
    await modal.present();
  }

  selectionChanged(event) {
    console.log('selectionChanged() Started ->>>>>>>>>');
    this.filterCalendarList(event[0].data.id);
  }

  masterCalendarChanged(masterCalendar){
    console.log('master calendar selectionChanged() Started ->>>>>>>>>');
    console.log(masterCalendar);
    if(this.currentMasterCalendar != masterCalendar.masterCalendarName){
      this.currentMasterCalendar = masterCalendar.masterCalendarName;
      this.selectedMasterCalendarCode = masterCalendar.masterCalendarCode;
    }
  }

  async saveMasterCalendar(){

    const alert = await this.alertController.create({
      cssClass: 'custom-confirm',
      subHeader: 'Update Master Calendar',
      message: 'Are you sure you want to change the master calendar ?',
      buttons: [
        {
          text: 'No',
          handler: () => {
            alert.dismiss(false);
            return false;
          }
        }, {
          text: 'Yes',
          handler: () => {
            alert.dismiss(true);
            return false;
          }
        }]
    });

    alert.onDidDismiss().then(async (res) =>   {
      if (res.data) {
        this.reportsService.updateCurrentMasterCalendar(this.selectedMasterCalendarCode);
        this.currentMasterCalendarCode = this.selectedMasterCalendarCode;
      }
    });

    await alert.present();
  }

  filterCalendarList(id){
    if (this.type === 'Core') {
      this.filteredCalendars = id === 0 ? this.calendars.filter((c) => c.type == this.type) : this.calendars.filter((c) => c.fiscalYearId === id);
    } else {
      this.filteredCalendars = id === 0 ? this.calendars.filter((c) => c.type == this.type) : this.calendars.filter((c) => c.calendarYearId === id);
    }
  }

  updateTable($event) {
    console.log('updateTable()');
    this.type = $event.detail.value;
    this.filterCalendarList(this.type === 'Core' ? this.currentFiscalYear : this.currentCalendarYear);
    //this.filteredCalendars = this.calendars.filter((c) => c.type == $event.detail.value);
  }

  createCalendar(type: string) {
    this.router.navigate(['/master-calendar-editor'], { queryParams: { type: type } });
  }

  sortLocal($event, index) {
    $event.preventDefault();
    $event.stopPropagation();

    this.calendars = this.calendars.slice(0, this.calendars.length);
  }
  async createException(showSchools: boolean, showTitle: boolean, showAgencies: boolean, showStudents: boolean) {
    const modal = await this.modalController.create({
      component: CalendarPeriod,
      componentProps: {
        showSchools: showSchools,
        showTitle: showTitle,
        showAgencies: showAgencies,
        showStudents: showStudents,
      },
    });
    modal.onDidDismiss().then((result) => {
      if (result.data === undefined || result.data === 'cancel') { return; }
      if (result.data.agencyId) {
        const range = {
          startDate: result.data.startDate,
          endDate: result.data.endDate,
          agencyId: result.data.agencyId,
          studentId: result.data.studentId
        };
        this.calendarService.addStudentAttendanceException(range).subscribe(() => {
          this.calendarService.studentAttendanceExceptions().subscribe((exceptions) => {
            this.studentExceptions = exceptions;
            this.studentExceptions = this.studentExceptions.filter(x => x.isActive);
          });
        });
      } else {
        const range = {
          description: result.data.title,
          startDate: result.data.startDate,
          endDate: result.data.endDate,
          schoolIds: result.data.schoolIds
        };
        this.calendarService.addException(range).subscribe(() => {
          this.calendarService.exceptions().subscribe((exceptions) => {
            this.exceptions = exceptions;
          });
        });
      }
    });
    modal.present();
  }
  onSelect($event) {
    const calendarId = $event.selected[0].id;
    this.router.navigateByUrl(`/master-calendar-editor/${calendarId}`);
  }

  async onSelectException($event) {
    const exception = $event.selected[0];
    const modal = await this.modalController.create({
      component: CalendarPeriod,
      componentProps: {
        editMode: true,
        entity: exception
      }
    });

  }
  daysDiff(end: Date, start: Date) {
    return m(end).diff(start, 'days');
  }
  ngOnDestroy() {
    console.log('destoy');
  }

  selectedRequest: any;
  agencyName: any;
  submittedBy: any;
  submissionDate: any;
  responsibleStaff: any;
  schoolName: any;
  retroStudentsCopy: any;
  attendanceRetroId: any;
  retroDetailMasterCalendar: any;
  retroDetailAttendanceDateTime: any;
  retroDetailNotes: any;
  retroApproverNotes: any;
  retroDetailScheduledActivity: any;
  TabToShow = 'RetroList';
  selectedFilter : any = 'Pending';
  retroDetailStudents: any = [];
  filteredRetroList: any = [];
  retroList: any = [];
  filteredRetroStudentList: any = [];
  studentList: any = [];
  selectedStudentsForApproval: any = [];
  public attendanceApproval: FormGroup;
  schoolId: any = null;
  studentsAttendanceRetro: any = [];
  selectionType = SelectionType.single;
  selectedRetro: any = [];
  retroStatusList: any = ['All', 'Pending', 'Approved', 'Custom', 'Rejected', 'Processed'];


  agencyList: any [] = [];
  schoolList: any [] = [];

  m = m;




  filterRetroDetailsStudents(ev){
    let query = ev.target.value
    console.log(this.queryText);
    this.retroDetailStudents = this.retroStudentsCopy.filter(f => f.displayLastName.toLowerCase().indexOf(query.toLowerCase()) > -1 || f.displayFirstName.toLowerCase().indexOf(query.toLowerCase()) > -1 );
  }

  clearRetroStudentSearch() {
    this.queryText = '';
    this.filteredRetroStudentList = this.studentList;
    this.retroDetailStudents = this.retroStudentsCopy;
  }

  getStudents(attendance) {
    this.studentsService.getStudentsForRetro(attendance.id).subscribe((data)=>{
      this.retroDetailStudents = data;
      _.each(this.retroDetailStudents, s => {
        s.fullName = `${s.firstName} ${s.lastName}`
      })
      this.retroStudentsCopy = this.retroDetailStudents;

      this.approveAttendance(attendance, this.retroDetailStudents)
      })

  }

  async approveAttendance(attendance, students){

    let studentList: any = [];
    this.loading = true;
    const attendanceRetro = {
      attendanceRetroId: attendance.id,
      approverNotes: '',
      status: 'Approved',
      approvedStudents: studentList
    };


    const modal = await this.modalController.create({
      component: SubmitAttendanceConfirmationComponent,
      cssClass: 'my-custom-modal-css-small',
      componentProps: {
        title: `Are you sure?`,
        text: `You are about to Approve a request for ${attendance.attendanceTime} for ${attendance.schoolName} by ${attendance.agencyName} with ${students.length} student(s).`,
      },
    });
    modal.onDidDismiss().then((response) => {
      if(response.role == 'confirm'){
        this.studentsService.approveAttenanceRetro(attendanceRetro).subscribe((data: any) => {
          this.fetchAttendanceRetroList();
          this.TabToShow ='RetroList';
        });
      } else {
        return
      }
    });

    return await modal.present();
  }


  async approveRetroAttendances(approveType)
  {
    let status;
    let studentList: any = [];


    if(approveType == 'reject'){
      status = "Rejected";
      studentList = [];
    }
    else if(approveType == 'approve_all'){
      status = "Approved";
      studentList = this.retroDetailStudents.map(s => s.id);
    }
    else{
      status = "Custom";
      studentList = this.selectedStudentsForApproval;
    }

    let approverNote = this.attendanceApproval.controls.approverNotes.value;
    this.loading = true;
    const attendanceRetro = {
      attendanceRetroId: this.attendanceRetroId,
      approverNotes: approverNote,
      status: status,
      approvedStudents: studentList
    };

    const isReject = approveType == 'reject';
    const type = isReject ? 'reject' : 'approve';

    const modal = await this.modalController.create({
      component: SubmitAttendanceConfirmationComponent,
      cssClass: 'my-custom-modal-css-small',
      componentProps: {
        title: `Are you sure?`,
        text: `You are about to ${type} a request for ${this.retroDetailAttendanceDateTime} for ${this.schoolName} by ${this.agencyName} with ${this.selectStudentToApprove.length} student(s).`,
        reject: isReject
      },
    });
    modal.onDidDismiss().then((response) => {
      if(response.role == 'confirm'){
        this.studentsService.approveAttenanceRetro(attendanceRetro).subscribe((data: any) => {
          this.fetchAttendanceRetroList();
          this.TabToShow ='RetroList';
        });
      } else {
        return
      }
    });

    return await modal.present();

  }

  selectStudentToApprove(event, id){
    console.log(event);
    const formArray: FormArray = this.attendanceApproval.get('students') as FormArray;

    if(event.target.checked){
      formArray.push(new FormControl(event.target.value));
      this.selectedStudentsForApproval.push(id);
    } else{
      let idx = this.selectedStudentsForApproval.indexOf(id);
      this.selectedStudentsForApproval.splice(idx, 1);

      let i: number = 0;
      formArray.controls.forEach((ctrl: FormControl) => {
        if(ctrl.value == event.target.value) {
          formArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  downloadFile(file){
    window.open(file, "_blank");
  }

  async CloseRetroDetails(){
    this.TabToShow ='RetroList';
    this.attendanceRetroId = undefined;
  }

  fetchAttendanceRetroList() {
    this.loading = true;
console.log(this.schoolId, 'school id==========')
    this.schoolDetailsService
      .getAttendanceRetroSummary(this.schoolId)
      .subscribe((data: any) => {
        console.log('fetchAttendanceRetroList(): data from service:', data);
        this.retroList = data;
        this.filteredRetroList = data;
        this.getAgencyList(data);
        this.getSchoolList(data);
        if(this.selectedFilter == null || this.selectedFilter == undefined){
          this.selectedFilter = 'Pending';
        }

        const that = this;
        setTimeout(function () {
          that.loading = false;
        }, 300);
      });
  }


  getAgencyList(requests){
    let list = [
      {agencyName: 'All Agencies', agencyShortCode: 'all'}
    ]
    _.each(requests, r => {
      list.push({
        agencyName: r.agencyName,
        agencyShortCode: r.agencyShortCode
      })
    })

    list = _.uniqBy(list, 'agencyShortCode');
    this.agencyList = list;
  }

  getSchoolList(requests){
    let list = [
      {schoolName: 'All Schools'}
    ]
    _.each(requests, r => {
      list.push({
        schoolName: r.schoolName,
      })
    })

    list = _.uniqBy(list, 'schoolName');
    this.schoolList = list;
  }

  retroFiles = [];

  async showRetroDetails(event) {
    // collects activity's ID
    console.log(event)
    this.selectedRequest = event.selected[0];
    this.retroFiles = event.selected[0].files;
    this.schoolName = event.selected[0].schoolName;
    this.agencyName = event.selected[0].agencyName;
    this.submittedBy = event.selected[0].createdBy;
    this.submissionDate = event.selected[0].createdOn;
    this.responsibleStaff = event.selected[0].staff;
    const id = event.selected[0].id;
    this.attendanceRetroId = id;
    this.retroDetailAttendanceDateTime = event.selected[0].attendanceTime;
    this.retroDetailMasterCalendar = event.selected[0].masterCalendar;
    this.retroDetailNotes = event.selected[0].notes;
    this.retroApproverNotes = event.selected[0].approverNotes;
    this.retroDetailScheduledActivity = event.selected[0].scheduledActivityName;
    let status = event.selected[0].status;
    if( status != 'Pending' || (this.profile.roles.includes(6) || this.profile.roles.includes(7) || this.profile.roles.includes(8))){
      this.TabToShow ='RetroDetails';
    }
    else{
      this.TabToShow ='RetroApproval';
    }

    this.studentsService.getStudentsForRetro(id).subscribe((data)=>{
      this.retroDetailStudents = data;
      _.each(this.retroDetailStudents, s => {
        s.fullName = `${s.firstName} ${s.lastName}`
      })
      this.retroStudentsCopy = this.retroDetailStudents;
      })

  }


  onAttendanceStatusFilterChange(event){
    this.selectedFilter = event;
    if(event == 'All'){
      this.filteredRetroList = this.retroList;
    } else {
      this.filteredRetroList = this.retroList;
      if(this.selectedAgency && !this.selectedSchool){
      this.filteredRetroList = this.filteredRetroList.filter(r => r.agencyShortCode == this.selectedAgency && r.status == event);
      }
      else if(this.selectedSchool && !this.selectedAgency){
      this.filteredRetroList = this.filteredRetroList.filter(r => r.schoolName == this.selectedSchool && r.status == event);
      }
      else if(this.selectedAgency && this.selectedSchool){
        this.filteredRetroList = this.filteredRetroList.filter(r => r.agencyShortCode == this.selectedAgency && r.schoolName == this.selectedSchool && r.status == event);
      }
      else {
        this.filteredRetroList = this.filteredRetroList.filter(r => r.status == event);
      }

    }
  }

  selectedAgency: any;

  onAgencyFilterChange(ev) {
    if(ev == 'all') {
      this.filteredRetroList = this.retroList;
      this.selectedAgency = undefined;
    } else {
      this.selectedAgency = ev;
      this.filteredRetroList = this.retroList.filter(r => r.agencyShortCode == ev)
    }
  }

  selectedSchool: any;

  onSchoolFilterChange(ev) {
    if(ev == 'All Schools') {
      this.filteredRetroList = this.retroList;
      this.selectedSchool = undefined
    } else {
      this.selectedSchool = ev;
      this.filteredRetroList = this.retroList.filter(r => r.schoolName == ev)
    }
  }

  onDateRangeUpdate(ev){
    const fromDate = m(ev[0]);
    const toDate = m(ev[1]);

    this.filteredRetroList = this.retroList.filter(r => m(r.attendanceTime.substring(0, 10)).isBetween(fromDate, toDate))
  }


  cancelRetroApproval(){
    this.TabToShow ='RetroList';
  }


  async cancelRetroRequest(req){
    console.log(req, 'the retro request to remove')

    const modal = await this.modalController.create({
      component: SubmitAttendanceConfirmationComponent,
      cssClass: 'my-custom-modal-css-small',
      componentProps: {
        title: 'Cancel Retro Attendance Request?',
        text: 'This action cannot be undone'
      },
    });
    modal.onDidDismiss().then((response) => {
      if(response.role == 'confirm'){
        //api: cancelRetroRequest() goes here
      } else {
        return
      }
    });

    return await modal.present();
  }

  get profile() {
    return JSON.parse(
      window.localStorage.getItem('_ionicstorage/_ionickv/userSubject')
    );
  }

  fetchAttendanceRetro(mastercalendarcode){
    this.studentsService.getStudentsBAttendanceRetro(Number(this.schoolId), mastercalendarcode)
    .subscribe((data: any) => {
      console.log('getStudentsBAttendanceRetro(): data from service:', data);
      this.studentsAttendanceRetro = data;
      const that = this;
      setTimeout(function () {
        that.loading = false;
      }, 300);
    });
  }

}
@Component({
  template: ``,
})
export class CalendarException { }

