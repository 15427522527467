// used for staff details, remove staff activity, scheduled activity details
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class StaffDetailsService {


  public idToken: string;

  constructor(public httpClient: HttpClient, public storage: Storage) { }
  // fetch staff details by ID
  getStaffDetails(id: number) {
    console.log('getStaffDetails()'); // todo: comment out logging as needed for prod
    return this.loadStaffDetails(id);
  }

  private loadStaffDetails(id: number) {
    console.log('loadStaffDetals() with url: ', environment.staffUrl + id);
    return this.httpClient.get(environment.staffUrl + id);
  }

  getPaginatedStudentAttendanceHistory(id: number, page: number, pageSize: number) {
    console.log('getPaginatedStudentAttendanceHistory()'); // todo: comment out logging as needed for prod
    return this.loadPaginatedStudentAttendanceHistory(id, page, pageSize);
  }

  private loadPaginatedStudentAttendanceHistory(id: number, page: number, pageSize: number) {
    console.log('loadPaginatedStudentAttendanceHistory() with url: ', environment.staffUrl + id);
    return this.httpClient.get(environment.staffUrl + 'studentattendance/' + id + '/paging/' + page + '/' + pageSize);
  }

  getStaffAssignments(id) {
    console.log('getStaffAssignments()'); // todo: comment out logging as needed for prod
    return this.loadStaffAssignments(id);
  }

  // fetch staff schedule
  private loadStaffAssignments(id) {
    const profile = JSON.parse(window.localStorage.getItem('_ionicstorage/_ionickv/userSubject'));
    const adminRoles: number[] = [6,7,8];

    if (adminRoles.includes(profile.roles[0])) {
      console.log('loadStaffAssignments() with url: ', environment.staffUrl + 'assignments/' + id + '/agency/' + profile.agency);
      return this.httpClient.get(environment.staffUrl + 'assignments/' + id + '/agency/' + profile.agency);
    }
    else {
      console.log('loadStaffAssignments() with url: ', environment.staffUrl  + 'assignments/' + id);
      return this.httpClient.get(environment.staffUrl + 'assignments/' + id);
    }
  }

  // fetch staff's certifications
  getCertificationsByStaff(staffId) {
    console.log('getCertificationsByStaff()'); // todo: comment out logging as needed for prod
    return this.loadCertificationsByStaff(staffId).pipe(map(this.processCertifications, this));
  }

  private loadCertificationsByStaff(staffId) {
    console.log('loadCertificationsByStaff() with url: ', environment.certificationUrl + 'bystaff/' + staffId);
    const header = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + this.idToken
    );
    return this.httpClient.get(environment.certificationUrl + 'bystaff/' + staffId, { headers: header });
  }
  getScheduleByStaff(staffId) {
    console.log('getScheduleByStaff()'); // todo: comment out logging as needed for prod
    return this.loadScheduleByStaff(staffId).pipe(map(this.processCertifications, this));
  }

  private loadScheduleByStaff(staffId) {
    console.log('loadScheduleByStaff() with url: ', environment.activitiesUrl + 'scheduledactivities/staff/' + staffId);
    this.storage.get('idToken').then(res => {
      this.idToken = res;
    });
    const header = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + this.idToken
    );
    return this.httpClient.get(environment.activitiesUrl + 'scheduledactivities/staff/' + staffId, { headers: header });
  }
  // fetch staff history
  getStaffHistory() {
    // console.log('getSchools()'); // todo: comment out logging as needed for prod
    return this.loadStaffHistory().pipe(map(this.processStaffHistory, this));
  }
  private loadStaffHistory() {
    // console.log('loadSchools() with url: ', environment.schoolsUrl);
    this.storage.get('idToken').then(res => {
      this.idToken = res;
    });
    const header = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + this.idToken
    );
    return this.httpClient.get(environment.studentHistoryUrl, { headers: header });
  }
  processStaffHistory(data: any) {
    return data;
  }
  processCertifications(data: any) {
    return data;
  }
}
