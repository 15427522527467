// used on admin calendar
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'update-certification-type',
  templateUrl: './update-certification-type.component.html',
  styleUrls: ['./update-certification-type.component.scss'],
})
export class UpdateCertificationTypeComponent implements OnInit {
  @Input() type;
  @Input() modal;
  @Input() update;
  modalText: any = '';
  form: FormGroup;
  constructor(private formBuilder: FormBuilder,
    private modalController: ModalController) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: ['', Validators.required],
      code: ['', Validators.required],
      effectiveDate: ['', Validators.required],
      certificateExpDays: ['', Validators.required]
    });
    this.form.valueChanges.subscribe(change => {
      if (this.form.dirty) {
        this.modal.backdropDismiss = false;
      }
    });
    // if updating a cert, form will populate with current details
    if (this.update) {
      this.form.addControl('isActive', new FormControl('', Validators.required));
      this.form.patchValue(this.type);
    }
    this.modalText = this.update ? 'Update' : 'Add';
  }

  close() {
    this.form.value.certificateExpDays = Number(this.form.value.certificateExpDays);
    let typeToUpdate = this.form.value;
    if (this.update) {
      this.type.name = this.form.value.name;
      this.type.description = this.form.value.description;
      this.type.code = this.form.value.code;
      this.type.effectiveDate = this.form.value.effectiveDate;
      this.type.certificateExpDays = this.form.value.certificateExpDays;
      this.type.isActive = this.form.value.isActive;
      typeToUpdate = this.type;
    } else {
      // all new certs created are active
      typeToUpdate.isActive = true;
    }
    this.modalController.dismiss(typeToUpdate);
  }

}
