// used for staff page, assign/remove activity staff, calendar, remove certification, scheduled activity modal, scheduled activity details,
// staff landing page, staff details, update certification, update staff
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Storage } from '@ionic/storage';
import { ToastController } from '@ionic/angular';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StaffService {

  public idToken: string;

  constructor(public httpClient: HttpClient, public storage: Storage, public toastController: ToastController) { }
  // fetch staff
  getStaff() {
    console.log('getStaff()'); // todo: comment out logging as needed for prod
    return this.loadStaff().pipe(map(this.processStaff, this));
  }

  private loadStaff() {
    console.log('loadStaff() with url Agc: ', environment.staffUrl);
    return this.httpClient.get(environment.staffUrl);
  }
  // fetch staff by search query
  getStaffSearch(queryText) {
    console.log('getStaffSearch()'); // todo: comment out logging as needed for prod
    return this.loadStaffSearch(queryText).pipe(map(this.processStaffSearch, this));
  }

  private loadStaffSearch(queryText) {
    console.log('loadStaffSearch() with url: ', environment.staffUrl + 'search/' + queryText);
    return this.httpClient.get(environment.staffUrl + 'search/' + queryText);
  }

  // fetch staff by agency search query
  getStaffSearchByAgency(agencyId, queryText) {
    console.log('getStaffSearchByAgency()'); // todo: comment out logging as needed for prod
    return this.loadStafStaffSearchByAgency(agencyId, queryText).pipe(map(this.processStaffSearch, this));
  }

  private loadStafStaffSearchByAgency(agencyId, queryText) {
    console.log('loadStafStaffSearchByAgency() with url: ', environment.staffUrl + 'searchbyagency/' + agencyId + '/' + queryText);
    return this.httpClient.get(environment.staffUrl + 'searchbyagency/' + agencyId + '/' + queryText);
  }

  getStaffForRetroAttendance(schoolId, agencyId) {
    return this.httpClient.get(`${environment.staffUrl}school/${schoolId}/agency/${agencyId}`);
  }

   // fetch staff from all agencies search query
   getStaffSearchFromAllAgencies(queryText) {
    console.log('getStaffSearchFromAllAgencies()'); // todo: comment out logging as needed for prod
    return this.loadStaffSearchFromAllAgencies(queryText).pipe(map(this.processStaffSearch, this));
  }

  private loadStaffSearchFromAllAgencies(queryText) {
    console.log('loadStaffSearchFromAllAgencies() with url: ', environment.staffUrl + 'searchallagenciesstaff/' + queryText);
    return this.httpClient.get(environment.staffUrl + 'searchallagenciesstaff/' + queryText);
  }

  processStaffSearch(data: any) {
    console.log('processStaffSearch() with', data.length, 'rows');
    return data;
  }
  // fetch staff by school id
  getStaffBySchool(schoolId) {
    console.log('getStaffBySchool()'); // todo: comment out logging as needed for prod
    return this.loadStaffBySchool(schoolId).pipe(map(this.processStaff, this));
  }
  // fetch staff by school ID and agency ID
  getStaffBySchoolBySchoolByAgency(schoolId, agencyId) {
    console.log('getStaffBySchoolByAgency()'); // todo: comment out logging as needed for prod
    return this.loadStaffBySchoolByAgency(schoolId, agencyId).pipe(map(this.processStaff, this));
  }

  private loadStaffBySchool(schoolId) {
    console.log('loadStaffBySchool() with url: ', environment.staffUrl);
    return this.httpClient.get(environment.staffUrl + 'school/' + schoolId);
  }

  loadStaffBySchoolByAgency(schoolId, agencyId) {
    console.log('loadStaffBySchoolByAgency() with url: ', environment.staffUrl);
    return this.httpClient.get(environment.staffUrl + 'school/' + schoolId + '/agency/' + agencyId);
  }
  // fetch staff full details by user details
  getStaffInfoByLoginName(staffName) {
    console.log('getStaffInfoByLoginName()'); // todo: comment out logging as needed for prod
    return this.loadStaffInfoByLoginName(staffName);
  }

  private loadStaffInfoByLoginName(staffName) {
    console.log('loadStaffInfoByLoginName() with url: ', environment.staffUrl + 'login/' + staffName);
    return this.httpClient.get(environment.staffUrl + 'login/' + staffName);
  }

  // fetch staff by user name
  getStaffByLoginName(staffName) {
    console.log('getStaffByLoginName()'); // todo: comment out logging as needed for prod
    return this.loadStaffByLoginName(staffName);
  }

  private loadStaffByLoginName(staffName) {
    console.log('loadStaffByLoginName() with url: ', environment.staffUrl + 'byusername/' + staffName);
    return this.httpClient.get(environment.staffUrl + 'byusername/' + staffName);
  }

  // POST request add new certification to staff member
  createCertification(cert: any, modal: any) {
    console.log('createCertificate() with url: ', environment.certificationUrl);

    return this.httpClient.post(environment.certificationUrl, cert).subscribe(
      result => {
        // Handle result
        console.log(result);
      },
      error => {
        this.showError(error);
      },
      () => {
        this.showSuccess();
      }
    );
  }
  // PUT request to update a certification
  updateCertificationType(cert: any, update: any) {
    console.log('createCertificate() with url: ', environment.certificationUrl);
    if (update) {
      return this.httpClient.put(environment.certificationUrl + 'types', cert).subscribe(
        result => {
          // Handle result
          console.log(result);
        },
        error => {
          this.showError(error);
        },
        () => {
          this.showSuccess();
        }
      );
    } else {
      return this.httpClient.post(environment.certificationUrl + 'types', cert).subscribe(
        result => {
          // Handle result
          console.log(result);
        },
        error => {
          this.showError(error);
        },
        () => {
          this.showSuccess();
        }
      );
    }
  }
  // fetch certification types
  getTypes() {
    console.log('getTypes()'); // todo: comment out logging as needed for prod
    return this.loadTypes().pipe(map(this.processStaff, this));
  }

  private loadTypes() {
    console.log('loadTypes() with url: ', environment.certificationUrl + 'types');
    const header = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + this.idToken
    );
    return this.httpClient.get(environment.certificationUrl + 'types', { headers: header });
  }
  // assign staff to a scheduled activity
  assignStaff(id: any, staffId: any, modal: any): Observable<any> {
    console.log('assignStaff()'); // todo: comment out logging as needed for prod
    return this.assignStaffActivity(id, staffId, modal);
  }
  // assign staff to a scheduled activity
  private assignStaffActivity(id, staffId, modal): Observable<any> {
    console.log('assignStaffActivity() with url: ', environment.staffUrl + 'enroll');

    let subject = new Subject();
    this.httpClient.put(environment.activitiesUrl + 'scheduledactivity/' + id + '/assignstaff/' + staffId, {}).subscribe(
      result => {
        // Handle result
        console.log(result);
      },
      error => {
        this.showError(error);
      },
      () => {
        subject.next();
        this.showSuccess();
      }
    );
    return subject;
  }
  // remove secondary staff member from a scheduled activity
  removeStaffActivity(id, staffId): Observable<any> {
    console.log('withdrawStudent() with url: ', environment.activitiesUrl + 'scheduleactivity/' + id + '/withdrawstaff/' + staffId);
    // tslint:disable-next-line: max-line-length
    let subject = new Subject<any>();
    // activities/scheduledactivity/{id}/withdrawstaff/{staffId}
    this.httpClient.delete(environment.activitiesUrl + 'scheduleactivity/' + id + '/withdrawstaff/' + staffId).subscribe(
      result => {
        // Handle result
        console.log(result);
      },
      error => {
        this.showError(error);
      },
      () => {
        subject.next();
        this.showSuccess();
      }
    );
    return subject;
  }
  // remove primary staff member from a scheduled activity
  // replace current primary staff member with user's selection
  removePrimaryStaffActivity(staffActId, primeStaffId, newStaffId): Observable<any> {
    console.log('withdrawStudent() with url: ', environment.activitiesUrl + 'scheduledactivity/' + staffActId +
      '/replacestaff/' + primeStaffId + '/with/' + newStaffId);
    // tslint:disable-next-line: max-line-length
    let subject = new Subject<any>();
    this.httpClient.put(environment.activitiesUrl + 'scheduledactivity/' + staffActId +
      '/replacestaff/' + primeStaffId + '/with/' + newStaffId, '/').subscribe(
        result => {
          // Handle result
          console.log(result);
        },
        error => {
          this.showError(error);
        },
        () => {
          subject.next();
          this.showSuccess();
        }
      );
    return subject;
  }
  // DELETE request remove staff's certification
  removeStaffCertification(staffCertId): Observable<any> {
    console.log('withdrawStaff Certification() with url: ', environment.certificationUrl + 'withdrawstaffcertification/' + staffCertId);
    // tslint:disable-next-line: max-line-length
    const subject = new Subject<any>();
    this.httpClient.delete(environment.certificationUrl + 'withdrawstaffcertification/' + staffCertId).subscribe(
      result => {
        // Handle result
        console.log(result);
      },
      error => {
        this.showError(error);
      },
      () => {
        subject.next();
        this.showSuccess();
      }
    );
    return subject;
  }
  // PUT request update staff details
  updateStaff(staff: any, modal: any) {
    console.log('updateStaff() with url: ', environment.staffUrl);
    return this.httpClient.put(environment.staffUrl + staff.id, staff).subscribe(
      result => {
        // Handle result
        console.log(result);
      },
      error => {
        this.showError(error);
      },
      () => {
        this.showSuccess();
      }
    );
  }

  processStaff(data: any) {
    console.log('processStaff() with', data.length, 'rows.');

    // generate the enrichmentCode from last 7 digits of the activityName.
    // let enrichmentCodeString = '';
    data.forEach((row: any) => {
      if (row.name) {
        row.status = row.isActive ? 'Active' : 'InActive';
        // const nameString = row.name.toString();
        // enrichmentCodeString = activityNameString.slice(activityNameString.length - 7);
        // row.enrichmentCode = parseInt(enrichmentCodeString, 10);
      }
    });

    return data;
  }

  async showSuccess() {
    const toast = await this.toastController.create({
      color: 'success',
      duration: 2500,
      message: 'Success!',
      showCloseButton: true,
      position: 'top'
    });
    await toast.present();
    setTimeout(function () {
      // window.location.reload();
    }, 1500);
  }

  async showError(error) {
    console.log(error)
    const toast = await this.toastController.create({
      color: 'danger',
      duration: 2500,
      message: 'Error!',
      showCloseButton: true,
      position: 'top'
    });
    await toast.present();
  }

  get profile() {
    return JSON.parse(window.localStorage.getItem('_ionicstorage/_ionickv/userSubject'));
  }

  hasRoles(roles: number[]): boolean {

    if (this.profile) {
      for (const aRole of this.profile.roles) {
        if (roles.includes(aRole)) {
          return true;
        }
      }
    }
    return false;
  }
}
