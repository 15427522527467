import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Components } from '@ionic/core';
import { ReportsService } from '../reports/reports.service';
import { environment } from '../../environments/environment';
import * as moment from 'moment';
import { AgenciesService } from '../agencies/agencies.service';
//import { saveAs } from 'file-saver';
import { DatePipe } from '@angular/common';

import {
  ModalController,
  NavParams,
  ToastController
} from '@ionic/angular';
import { AwardsService } from '../awards/awards.service';
import { SchoolsService } from '../schools/schools.service';
import { ThrowStmt } from '@angular/compiler';
import { ElementArrayFinder } from 'protractor';
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { FundingService } from '../funding/funding.service';
import { CalendarService } from './../core/services/calendar.service';
import { GlobalShareService } from '../core/services/global-share.service';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'generate-reports',
  templateUrl: './generate-reports.component.html',
  styleUrls: ['./generate-reports.component.scss'],
})
export class GenerateReportsComponent implements OnInit {
  public reportForm: FormGroup;
  loading = false;
  @Input() create;
  //yearList = new Date().getFullYear();
  Reports: any;
  @Input() modal: Components.IonModal;
  isConfirm = false;
  @Input() isCancel;
  showRadioOption = true;
  blob: any;
  reportS3url: any;
  generateReportText: any = 'Generate Report';
  generatedReportName: any;
  report: any;
  agenciesList: any = [];
  yearOpt: any = 0;
  agencyId: any = -1;
  awardsList: any = [];
  funderList: any = [];
  schoolsList: any = [];
  enrichmentCodesList: any = [];
  calendarYearList: any = [];
  reportName: any;
  showAgency: boolean;
  showFYCYOpt: boolean;
  showSchools: boolean;
  showYear: boolean;
  expandFYDP: boolean;
  showCYYear: boolean;
  showAnnualOutcome: boolean;
  fiscalYearList: any[];
  showStart: boolean;
  showAwards: boolean;
  showEnd: boolean;
  hideAgency:boolean = true;
  disableGenerate = false;
  disableStartEnd = true;
  disableSchools = false;
  disableAgency = false;
  disableEnrichmentCodes = false;
  showEnrichmentCodes: boolean;
  disableAwards = false;
  multipleAgencies = false;
  minDate: Date = null;
  start: any = null;
  maxDate: Date = null;
  allDates: any = [];
  enabledDates: any = [];
  highlightOnlyReportDates: DatepickerDateCustomClasses[] = [];
  end: any = null;
  fyear: any;
  reportstatus: any;
  selectedAgencyId: any = -1;
  // s3reports:Array<any> = [];
  constructor(public router: Router, public formBuilder: FormBuilder,
    private reportService: ReportsService, public agenciesService: AgenciesService,
    public awardsService: AwardsService, public schoolsService: SchoolsService,
    public fundingService: FundingService, public calendarService: CalendarService,
    public toastController: ToastController, private globalShareService: GlobalShareService) {
    this.reportForm = formBuilder.group({
      agencies: [[], [Validators.required]],
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      fiscalYearId: [0, Validators.required],
    });
  }
  // fetch reports data
  fetchData() {
    this.loading = true;
    this.reportService.getReports().subscribe((data: any) => {
      console.log('fetchData(): data from service:', data);
      this.Reports = data;
    });
  }
  // fetch award data by school ID
  fetchAwards(id) {
    this.disableAwards = false;
    this.awardsService.getAwardsBySchoolId(id).subscribe((data: any) => {
      console.log('fetchAwards(): data from agencies service:', data);
      this.awardsList = [];
      data = Array.from(new Set(data.map(a => a.award.id))).map(id => {
        return data.find(a => a.award.id === id);
      });
      data.forEach(element => {
        const dooType = element.award.dooType === 1 ? 'CORE' : 'SUPP';
        const type = element.award.type === 1 ? 'Before School' : 'After School';
        const funder = element.award.fundingSource.funder ? element.award.fundingSource.funder.shortCode : 'N/A';
        element.award.text = funder + ' - ' + element.award.fundingSource.cohort + ' - ' + dooType + ' - ' + type;
        if (element.award.isActive) {
          this.awardsList.push(element.award);
        }
      });
      this.disableAwards = this.awardsList.length > 0 ? false : true;
    });
  }
  // fetch schools data by Agency ID
  fetchSchools(id) {
    this.disableSchools = false;
    this.schoolsService.getSchoolsByAgency(id).subscribe((data: any) => {
      console.log('fetchAgencyList(): data from agencies service:', data);
      this.schoolsList = data;
      if (this.report.reportName.includes('Participation') || this.report.reportName.includes('Unduplicated Student Names')
        || (this.report.reportName.includes('Attendance Summary') && !this.hasRoles([7,8]))) {
        this.schoolsList.unshift({ 'id': -1, 'schoolName': 'All Schools' });
        this.reportForm.get('schoolId').setValue(-1);
      }
      this.disableSchools = this.schoolsList.length > 0 ? false : true;
    });
  }
  // fetch schools data
  fetchAllSchools() {
    this.disableSchools = false;
    this.schoolsList = [...this.globalShareService.allSchools];
    this.disableSchools = this.schoolsList.length > 0 ? false : true;
  }

  fetchEnrichmentCode() {
    this.disableEnrichmentCodes = false;
    this.calendarService.getEnrichmentCodes().subscribe((data: any) => {
      console.log('getEnrichmentCodes(): data :', data);
      this.enrichmentCodesList = data;
      this.disableEnrichmentCodes = this.enrichmentCodesList.length > 0 ? false : true;
    });
  }
  // fetch agency data
  fetchAgencyList() {
    this.agenciesService.getAgencies().subscribe((data: any) => {
      console.log('fetchAgencyList(): data from agencies service:', data);
      this.agenciesList = data.filter(x => x.isActive);
      if (this.report.reportName.includes('Grant Export')) {
        this.agenciesList.unshift({ 'id': -1, 'name': 'All Agencies' });
        this.reportForm.get('agencies').setValue([-1]);
      }
      else if (this.report.reportName.includes('Attendance Summary') && this.hasRoles([6,7,8])){
        const agencies = [];
        agencies.push(this.profile.agency);
        this.reportForm.get('agencies').patchValue(agencies);
        this.disableAgency = true;

        this.fetchSchools(this.profile.agency);
      }
    });
  }
  // fetch fiscal years data
  fetchFiscalYear() {
    this.reportService.getFiscalYears().subscribe((data: any) => {
      this.fiscalYearList = data.filter(x => x.isActive);
      console.log('generatereportpopup:fetchFiscalYear(): data from fiscalYear service:', this.fiscalYearList);
      if (this.report.reportName.includes('Projection')) {
        const currentFiscalYear = this.fiscalYearList.find(x => x.isCurrent);
        this.reportForm.get('fiscalYearId').setValue(currentFiscalYear.id);
        this.reportForm.get('fiscalYearId').disable();
        this.reportForm.get('startDate').disable();
        this.reportForm.get('endDate').disable();
      }
    });
  }
  // fetch CY funders data
  fetchFunders() {
    this.fundingService.getFundingCy().subscribe((data: any) => {
      console.log('fetchFunders(): data from funding service:', data);
      data.forEach(x => {
        x.name = x.fundingSource.funder.name;
        x.id = x.fundingSource.funder.id;
      });
      this.funderList = data.filter(x => (x.fundingSource.funder.name === 'ASSETS'
        || x.fundingSource.funder.name === 'ASES' || x.fundingSource.funder.name === '21CCLC'));
    });
  }
  // fetch CY data
  fetchCalendarYears() {
    this.reportService.getCalendarYears().subscribe((data: any) => {
      console.log('fetchCalendarYears(): data from fiscalYear service:', data);
      this.calendarYearList = data.filter(x => x.isActive);
    });
  }
  // sets start and end date range once user inputs fiscal year
  onFYChange(event) {
    this.disableStartEnd = false;
    this.start = null;
    this.end = null;
    this.minDate = moment(event[0].data.fromDate).toDate();
    this.maxDate = moment(event[0].data.toDate).toDate();
    this.start = moment(event[0].data.fromDate).format('MM/DD/YYYY');
    this.end = moment(event[0].data.toDate).format('MM/DD/YYYY');
    this.reportForm.get('startDate').setValue(this.start);
    this.reportForm.get('endDate').setValue(this.end);
  }
  // sets start and end date range once user inputs calendar year
  onCYChange(event) {
    this.disableStartEnd = false;
    this.start = null;
    this.end = null;
    this.minDate = moment(event[0].data.fromDate).toDate();
    this.maxDate = moment(event[0].data.toDate).toDate();
    this.start = moment(event[0].data.fromDate).format('MM/DD/YYYY');
    this.end = moment(event[0].data.toDate).format('MM/DD/YYYY');
    this.reportForm.get('startDate').setValue(this.start);
    this.reportForm.get('endDate').setValue(this.end);
  }
  // collects agencies selected
  onAgencyChange(event) {
    const agencies = [];
    event.forEach(element => {
      agencies.push(element.data.id);
    });
    // if report selected in 'Grant Export', user can only choose 1 agency
    if (this.report.reportName.includes('Grant Export')) {
      if (agencies.includes(-1)) {
        this.multipleAgencies = false;
        this.reportForm.get('agencies').setValue([-1]);
      } else {
        this.multipleAgencies = true;
      }
    }
    if (this.report.reportName.includes('Daily Attendance Backup')) {
      this.multipleAgencies = false;
      this.selectedAgencyId = event[0].data.id;
      this.disableSchools = true;
      if (event.length === 0) {
        this.schoolsList = [];
      }
      if (event.length === 1) {
        this.fetchSchools(event[0].data.id);
      }
      return;
    }
    if (this.displaySchools() && this.hasRoles([1, 2, 3, 4, 5])) {
      this.disableSchools = true;
      if (event.length === 0) {
        this.schoolsList = [];
      }
      if (event.length === 1) {
        this.fetchSchools(event[0].data.id);
      }
      if (event.length > 1) {
        this.schoolsList.unshift({ 'id': -1, 'schoolName': 'All Schools' });
        this.reportForm.get('schoolId').setValue(-1);
        this.disableSchools = true;
      }
    }
    this.reportForm.get('agencies').patchValue(agencies);
  }
  // displays awards
  onSchoolChange(event) {
    this.enabledDates = this.allDates;
    this.highlightOnlyReportDates = [];
    if (this.displayAwards()) {
      this.disableAwards = true;
      this.fetchAwards(event[0].data.id);
    }
    const requestReport = this.reportForm.value;
    if(this.hasRoles([6,7,8])){
      if(this.agencyId && event[0].data.id) {

          var currentAgencyId = this.selectedAgencyId == -1 ? this.agencyId : this.selectedAgencyId;
          this.reportService.getReportDates(currentAgencyId, event[0].data.id).subscribe((dates)=>{
          this.availableDate = dates;

          this.enableReportAvailableDates(this.availableDate);

        })
      }
    }else{
      if(requestReport.agencies && event[0].data.id) {
        this.reportService.getReportDates(requestReport.agencies, event[0].data.id).subscribe((dates)=>{
          this.availableDate = dates;
          this.enableReportAvailableDates(this.availableDate);
        })
      }
    }


  }

  availableDate: any = []

  enableReportAvailableDates(reportAvailableDate){
    if(this.report.reportName.includes('Daily Attendance Backup')){
      this.enabledDates = reportAvailableDate.map(f => Date.parse(f.to_char + " 23:59:59"));
      let reportDates = reportAvailableDate.map(f => f.to_char + " 23:59:59");
      reportDates.forEach(date => {
        let highlightedTo = {
          date: new Date(date),
          classes:['highlight-reportdate']
        };
        this.highlightOnlyReportDates.push(highlightedTo);
      });
     }
  }

  // fetches user's profile details
  get profile() {
    return JSON.parse(window.localStorage.getItem('_ionicstorage/_ionickv/userSubject'));
  }

  highlightReportDate(date, mode){
    if (mode === 'day') {
      var dayToCheck = new Date(date).setHours(0,0,0,0);
      var currentDay = new Date().setHours(0,0,0,0);

        if (dayToCheck === currentDay) {
          return 'highlight-date';
        }
    }

    return ''
  }

  // limits user's permissions based on roles
  hasRoles(roles: number[]): boolean {
    if (this.profile) {
      for (const aRole of this.profile.roles) {
        if (roles.includes(aRole)) {
          return true;
        }
      }
    }
    return false;
  }

  displayAnnualOutcome() {
    this.showAnnualOutcome = this.report.reportName.includes('Annual Outcome');
    return this.showAnnualOutcome;
  }


  displayAgency() {
    this.showAgency = ((this.report.reportName.includes('Dosage')
      || this.report.reportName.includes('HS')
      || this.report.reportName.includes('Participation ')
      || (this.report.reportName.includes('Attendance') && !this.report.reportName.includes('Total Daily Attendance Per Site by Enrichment Code'))
      || this.report.reportName.includes('Grant Export')
      // || this.report.reportName.includes('Unduplicated Student Names')) && this.hasRoles([1, 2, 3, 4, 5, 6])) ? true : false;
      || this.report.reportName.includes('Unduplicated Student Names')
      || this.report.reportName.includes('Daily Attendance Backup') && !this.hasRoles([6,7,8]))) ? true : false;

    return this.showAgency;
  }

  displayFYCYOpt() {
    this.showFYCYOpt = this.report.reportName.includes('Attendance Summary') && this.report.reportName.includes('Provider');
    return this.showFYCYOpt;
  }

  displaySchools() {
    this.showSchools = this.report.reportName.includes('Participant Dosage') || (this.report.reportName.includes('HS'))
      || this.report.reportName.includes('Progress By Grant Type')
      || this.report.reportName.includes('Participation')
      || (this.report.reportName.includes('Attendance') && !this.report.reportName.includes('(by Provider)') && !this.report.reportName.includes('Total Daily Attendance Per Site by Enrichment Code'))
      || this.report.reportName.includes('Unduplicated Student Names')
      || this.report.reportName.includes('Daily Attendance Backup') ? true : false;
    return this.showSchools;
  }

  doOpen() { this.expandFYDP = true; console.log('SingleDemoComponent.doOpen'); }
  doClose() { this.expandFYDP = false; console.log('SingleDemoComponent.doClose'); }
  displayYear() {
    if (this.yearOpt === 1) {
      this.showYear = true;
      return this.showYear;
    } else {
      this.showYear = this.report.reportName.slice(0, 6) === 'Global' || (this.report.reportName.includes('HS'))
        || this.report.reportName.includes('Annual Outcome')
        || this.report.reportName.includes('Dosage')
        || this.report.reportName.includes('Projection')
        || this.report.reportName.includes('Participation')
        || (this.report.reportName.includes('Summary') && !this.report.reportName.includes('Provider'))
        || this.report.reportName.includes('Grant Export')
        || this.report.reportName.includes('Unduplicated Student Names') || this.report.reportName.includes('Total Daily Attendance Per Site by Enrichment Code')
        || this.report.reportName.includes('Activity') ? true : false;
    }
    return this.showYear;
  }
  displayCYYear() {
    if (this.yearOpt === 2) {
      this.showCYYear = true;
      return this.showCYYear;
    }
    this.showCYYear = this.report.reportName.includes('Progress By Grant Type')
      ? true : false;
    return this.showCYYear;
  }
  displayAwards() {
    this.showAwards = this.report.reportName.includes('Progress By Grant Type') ? true : false;
    return this.showAwards;
  }
  displayStart() {
    this.showStart = this.report.reportName.includes('Activity') || (this.report.reportName.includes('HS'))
      || this.report.reportName.includes('Dosage') || this.report.reportName.includes('Progress By Grant Type')
      || this.report.reportName.includes('Projection')
      || this.report.reportName.includes('Participation')
      || this.report.reportName.includes('Daily Attendance Backup')
      || this.report.reportName.includes('Summary')
      || this.report.reportName.includes('Total Daily Attendance Per Site by Enrichment Code')
      || this.report.reportName.includes('Unduplicated Student Names') ? true : false;
    return this.showStart;
  }
  displayEnd() {
    this.showEnd = this.report.reportName.includes('Activity') || (this.report.reportName.includes('HS'))
      || this.report.reportName.includes('Dosage') || this.report.reportName.includes('Progress By Grant Type')
      || this.report.reportName.includes('Participation')
      || this.report.reportName.includes('Projection')
      || this.report.reportName.includes('Summary') || this.report.reportName.includes('Total Daily Attendance Per Site by Enrichment Code')
      || this.report.reportName.includes('Unduplicated Student Names') ? true : false;
    return this.showEnd;
  }
  displayEnrichmentCodes() {
    this.showEnrichmentCodes = this.report.reportName.includes('Total Daily Attendance Per Site by Enrichment Code') ? true : false;
    return this.showEnrichmentCodes;
  }
  selectionChanged(event) {
    console.log(event);
    event = event.sort((a, b) => (a.value > b.value) ? 1 : -1);
  }

  yearOptionChange(event) {
    if (event.detail.value === 1) {
      this.fetchFiscalYear();
      this.yearOpt = 1;
    } else {
      this.fetchCalendarYears();
      this.yearOpt = 2;
    }
  }

  cancelReports() {
    this.modal.dismiss('cancel');
    // this.modal.dismiss({ 'cancel': true });
  }

  reportMsg = ''
  downloadS3(){
    this.reportMsg = ''
    const requestReport = this.reportForm.value;
    const pipe = new DatePipe('en-US');
    const startDate = pipe.transform(requestReport.startDate, 'yyyy-MM-dd');
    var findReport = this.availableDate.find(a=>a.to_char == startDate)
    if(findReport){
      let agency = requestReport.agencies;
      let schoolId = requestReport.schoolId
      if(this.hasRoles([6,7,8])){
       agency = this.agencyId;
      }
      this.reportService.getAttendanceReport(agency,requestReport.schoolId,startDate).subscribe((response:any)=>{
        window.open(response.download)
      });
    }else{
      this.reportMsg = 'No report available for this date'
    }

  }
  generateReports(reportToBuild) {

    if(reportToBuild.reportName == 'Daily Attendance Backup'){
      this.downloadS3();
      return;
    }
    this.reportstatus = 'Report can be downloaded once its generated and ready for download.'
    let reporturl = this.reportService.getS3ReportUrl(reportToBuild.id);
    console.log('generateReports(): ', reportToBuild.id, reporturl);
    if (reporturl === null || reporturl === undefined) {
      console.log('getReport ' + reportToBuild);
      console.log(reportToBuild);
      console.log(reportToBuild.id);
      const pipe = new DatePipe('en-US');
      this.disableGenerate = true;
      this.generateReportText = 'Generating...';
      const requestReport = this.reportForm.value;

      if (requestReport.schoolId !== undefined) {
        if (requestReport.schoolId === -1 && !this.report.reportName.includes('Attendance Summary')) {
          requestReport.schools = [];
        } else {
          requestReport.schools = [requestReport.schoolId];
        }
        delete requestReport.schoolId;
      } else {
        requestReport.schools = [];
      }
      if (requestReport.agencies && requestReport.agencies.length > 0) {
        if (requestReport.agencies.includes(-1)) {
          requestReport.agencies = [];
        }
      }
      requestReport.reportId = reportToBuild.id;
      requestReport.category = this.report.category;
      requestReport.reportName = this.report.reportName;
      requestReport.agencies = this.showAgency ? requestReport.agencies : [environment.agencyId];
      requestReport.fiscalYearId = this.showYear ? requestReport.fiscalYearId : null;
      let startDate = null;
      let endDate = null;

      if (requestReport.startDate != null) {
        startDate = pipe.transform(requestReport.startDate, 'yyyy-MM-dd');
      }
      if (requestReport.endDate) {
        endDate = pipe.transform(requestReport.endDate, 'yyyy-MM-dd');
      }

      this.generatedReportName = reportToBuild.reportName;
      requestReport.startDate = startDate;
      requestReport.endDate = endDate;

      /*`if (moment(requestReport.startDate).isAfter(moment(requestReport.endDate))) {
        this.throwValidationError('Start Date cannot be after End Date');
        this.disableGenerate = false;
        this.generateReportText = 'Generate Report';
      } else {
        this.reportService.generateGlobalReport(requestReport).subscribe((res) => {
          // console.log('Response:', res);
          this.isConfirm = true;
          this.blob = new Blob([res], { type: res.type });
        },
          error => {
            // console.log('Error:', error);
            this.disableGenerate = false;
            this.generateReportText = 'Generate Report';
            this.isConfirm = false;
          });
      }*/

      if (moment(requestReport.startDate).isAfter(moment(requestReport.endDate))) {
        this.throwValidationError('Start Date cannot be after End Date');
        this.disableGenerate = false;
        this.generateReportText = 'Generate Report';
      } else {
        console.log('submitReportRequest:', requestReport);
        this.reportService.submitReportRequest(requestReport).subscribe((res) => {
          // console.log('Response:', res);
          // this.isConfirm = true;
          // this.blob = new Blob([res], { type: res.type });
          this.reportService.AddS3ReportUrl(requestReport.reportId, res.reportUrl);
          this.modal.dismiss('submitted');
        },
          error => {
            // console.log('Error:', error);
            this.disableGenerate = false;
            this.generateReportText = 'Generate Report';
            this.isConfirm = false;
          });
      }
    } else {
      // this.isConfirm = true;
    }
  }

  async throwValidationError(message: string) {
    const toast = await this.toastController.create({
      color: 'danger',
      duration: 2500,
      message: message,
      showCloseButton: true,
      position: 'top'
    });
    await toast.present();
  }

  downloadFile(reportId) {
    let reporturl = this.reportService.getS3ReportUrl(reportId);
    console.log('downloadFile for report:', reportId, reporturl);

    let reportName = reporturl.split('/')[4].split('.')[0];
    this.reportService.reportRequestComplete(reportName).subscribe((res) => {

      console.log('downloadReport: Response:', res);
      // this.isConfirm = true;
      // this.blob = new Blob([res], { type: res.type });
      this.modal.dismiss('submitted');
      this.blob = '';
      this.showSuccess();
      this.generatedReportName = '';
      window.location.href = reporturl;
    },
      error => {
        console.log('downloadReport : Error:', error);
        this.disableGenerate = true;
        this.generateReportText = 'Generating ...';
        // this.isConfirm = true;
        this.reportstatus = 'Report generation is in progress.... Please try later or contact support team.'
      });
    // saveAs(this.blob, this.generatedReportName + '.xlsx');

  }

  confirmationBox() {
    // this.isConfirm = true;
  }

  async showSuccess() {
    const toast = await this.toastController.create({
      color: 'success',
      duration: 2500,
      message: 'Success!',
      showCloseButton: true,
      position: 'top'
    });
    await toast.present();
  }

  async showError(error) {
    const toast = await this.toastController.create({
      color: 'danger',
      duration: 2500,
      message: 'Error!',
      showCloseButton: true,
      position: 'top'
    });
    await toast.present();
  }
  ngOnInit() {
    this.reportstatus = 'Report can be downloaded once its generated and ready for download.'
    let reporturl = this.reportService.getS3ReportUrl(this.report.id);
    console.log('generateReports(): ', this.report.id, reporturl);
    if (reporturl === null || reporturl === undefined) {
      this.fetchData();
      this.reportForm.statusChanges.subscribe(change => {
        if (this.reportForm.dirty) {
          this.modal.backdropDismiss = false;
        }
      });
      this.agencyId = this.profile.agency;
      if (this.displayAnnualOutcome()) {
        this.reportForm.addControl('funderId', new FormControl('', Validators.required));
        this.reportForm.get('agencies').setValue([this.agencyId]);
        this.reportForm.get('fiscalYearId').setValue(null);
        this.reportForm.removeControl('startDate');
        this.reportForm.removeControl('endDate');
        this.fetchFunders();
      }
      if (this.report.reportName.includes('Projection')) {
        if (this.agencyId === null) {
          this.reportForm.removeControl('agencies');
        } else {
          this.reportForm.get('agencies').setValue([this.agencyId]);
        }
      }
      if (this.report.reportName.includes('Activity')) {
        this.disableStartEnd = false;
        if (this.agencyId) {
          this.reportForm.get('agencies').setValue([this.agencyId]);
        } else {
          this.reportForm.removeControl('agencies');
        }
      }
      if (this.report.reportName.includes('Global Report')) {
        this.reportForm.get('fiscalYearId').setValue(null);
        this.reportForm.removeControl('agencies');
        this.reportForm.removeControl('startDate');
        this.reportForm.removeControl('endDate');
      }
      if (this.report.reportName.includes('Progress By Grant Type')) {
        this.disableAwards = true;
        this.reportForm.removeControl('agencies');
        if (this.hasRoles([6, 7])) {
          this.fetchSchools(this.agencyId);
        } else {
          this.fetchAllSchools();
        }
      }
      if (this.report.reportName.includes('Total Daily Attendance Per Site by Enrichment Code')) {
        this.reportForm.removeControl('agencies');
        this.disableEnrichmentCodes = true;
        this.fetchEnrichmentCode();

      }
      if (this.displayAwards()) {
        this.reportForm.addControl('awardId', new FormControl('', Validators.required));
      }
      if (this.hasRoles([6, 7])) {
        if (!this.report.reportName.includes('Global Report') && !this.report.reportName.includes('Progress By Grant Type')
          && !this.report.reportName.includes('Total Daily Attendance Per Site by Enrichment Code')) {
          this.reportForm.get('agencies').setValue([this.agencyId]);
        }
      }
      if (this.displayAgency()) {
        this.fetchAgencyList();
        if (this.report.reportName.includes('Participation')
          || (this.report.reportName.includes('Attendance Summary') && this.report.reportName.includes('Provider'))) {
          this.multipleAgencies = true;
        }
      }
      if (this.displaySchools()) {
        if (this.hasRoles([6, 7])) {
          this.fetchSchools(this.agencyId);
        }
        this.reportForm.addControl('schoolId', new FormControl('', Validators.required));
      }
      if (this.displayEnrichmentCodes()) {
        this.reportForm.addControl('enrichmentCode', new FormControl('', Validators.required));
      }
      if (this.displaySchools() && !this.hasRoles([6, 7])) {
        if (!this.report.reportName.includes('Attendance Summary')){
          this.disableSchools = true;
        }
      }
      if (this.displayAwards()) {
        this.reportForm.addControl('awardId', new FormControl('', Validators.required));
        this.disableAwards = true;
      }
      if (this.displayYear()) {
        this.fetchFiscalYear();
      }
      if (this.displayCYYear()) {
        this.reportForm.removeControl('fiscalYearId');
        this.reportForm.addControl('calendarYearId', new FormControl('', Validators.required));
        this.fetchCalendarYears();
      }
      if (this.report.reportName.includes('Daily Attendance Backup')) {
        const start = new Date(2021,8,19)
        const end = moment(new Date()).add(-1,'d').toDate();
        this.disableStartEnd = false;
        this.minDate = start;
        this.maxDate = end;
        let startDate = this.minDate;
        this.allDates.push(this.minDate);

        while(startDate <= this.maxDate){
          var nextDate = startDate.setDate(startDate.getDate() + 1);
          this.allDates.push(nextDate);
          startDate = new Date(nextDate);
        }

        if(this.hasRoles([6,7,8]))
          this.reportForm.removeControl('agencies');
        this.reportForm.removeControl('endDate');
        this.reportForm.removeControl('fiscalYearId');
        if (this.hasRoles([6, 7, 8])) {
          this.hideAgency = false;
          this.fetchSchools(this.agencyId);
        }
      }
    } else {
      // this.isConfirm = true;
      // this.reportstatus = "Report can be downloaded once its generated and ready for download.";
    }
  }
}

