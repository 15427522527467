import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { ModalAlertComponent, ModalComponent } from '../modal-alert/modal-alert.component';
import { SystemReconService } from './system-recon.service';
import { Components } from '@ionic/core';

@Component({
  selector: 'system-recon',
  templateUrl: './system-recon.component.html',
  styleUrls: ['./system-recon.component.scss'],
})
export class SystemReconComponent implements OnInit {
  columns = [
    { name: 'Updated On', prop: 'createDate' },
    { name: 'Updated By', prop: 'staff.fullName' },
    { name: 'Recon Date', prop: 'reconciliationDate' },
    { name: 'Is Current', prop: 'isCurrent' },
  ];
  filteredRows: any = [];
  loading = false;
  n: number = 5;
  reconDate: Date = new Date();
  minStartDate: Date = new Date('01/01/2022');

  @Input() modal: Components.IonModal;

  constructor(
    private systemReconService: SystemReconService,
    public alertController: AlertController
  ) { }

  ngOnInit() {
    this.fetchData();
  }

  fetchData() {
      this.loading = true;
      this.systemReconService.getLastNSystemReconDate(this.n).subscribe((data: any) => {
        this.filteredRows = data;
        console.log(this.filteredRows);
        this.filteredRows.filter((row: any) => {
          if(row.isCurrent == true){
            console.log(row);
            this.minStartDate = new Date(row.reconciliationDate);
            this.minStartDate.setDate(this.minStartDate.getDate() + 1);
          }
        });

        console.log('fetchData(): done');
        this.loading = false;
      },
      (err:any) =>{
        console.log(err);
        this.loading = false;
      });
  }

  async submitReconcile(){
    console.log(this.reconDate);
    const alert = await this.alertController.create({
      cssClass: 'custom-confirm',
      subHeader: 'Confirm Reconciliation',
      message: 'This action can NOT be undone.<br> Please note that it may take up to 24 hours for this data to be processed in the system and for all reports to reflect the new new numbers.<br>Are you sure you want to update ?',
      buttons: [
        {
          text: 'No',
          handler: () => {
            alert.dismiss(false);
            return false;
          }
        }, {
          text: 'Yes',
          handler: () => {
            alert.dismiss(true);
            return false;
          }
        }]
    });

    alert.onDidDismiss().then(async (res) =>   {
      if (res.data) {
        this.systemReconService.updateSystemReconDate(this.reconDate.toISOString());
        this.modal.dismiss('updated')
      }
    });

    await alert.present();


    const options: ModalComponent = {
      header: 'Confirm Reconciliation',
      body: `This action can <b>NOT</b> be undone.<br>Please note that it may take up to 24 hours for this data to be processed in the system and for all reports to reflect the new new numbers.`,
      model: ""
    };

  }

}
