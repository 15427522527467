// used for scheduled activity details page
import { Component, OnInit, ViewChild, ChangeDetectionStrategy, Input } from '@angular/core';
import { StringUtil } from '../shared/string-util';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { StudentsService } from '../students/students.service';
import { Components } from '@ionic/core';
import * as moment from 'moment';
import { AgencyDetailsService } from '../agency-details/agency-details.service';
import { SchoolToStudentsService } from '../school-to-students/schoolToStudents.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'scheduled-activities-enrollment',
  templateUrl: './scheduled-activities-enrollment.component.html',
  styleUrls: ['./scheduled-activities-enrollment.component.scss'],
})
export class ScheduledActivitiesEnrollmentComponent implements OnInit {

  @Input() students;
  @Input() rosterRows;
  @Input() schoolId;
  @Input() activityId;
  @Input() activityName;
  @Input() masterCalendarCode;

  // @Input() calenderType;
  // @Input() calenderId;
  // @Input() calenderIdDesc;
  // calenderTypeDesc: any;
  @Input() modal: Components.IonModal;
  studentActivity: any = {};
  loading = false;
  queryText = '';
  rows: any = [];
  filteredRows: any = [];
  columns = [
    { name: 'Name', prop: 'concatName' },
    { name: 'Student ID', prop: 'lausd' },
    { name: 'School', prop: 'school.schoolName' },
    { name: 'Source', prop: 'source' },
  ];
  columnMode = ColumnMode.force;
  filterColumns = ['firstName', 'middleName', 'lastName', 'studentId'];
  defaultSort = [{ prop: 'lastName', dir: 'asc' }];
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  studentIds: any = [];

  constructor(private studentsService: StudentsService,
    private agencyDetailsService: AgencyDetailsService,
    private schoolToStudentsService: SchoolToStudentsService,
    public modalController: ModalController) { }
  // fetch students by school id and agency id
  fetchData() {
    this.rosterRows.forEach(x => {
      this.studentIds.push(x.id);
    });
    this.schoolToStudentsService.getStudentsByAgencyBySchoolbyMasterCalendar(this.schoolId, this.profile.agency,this.masterCalendarCode).subscribe((data: any) => {
      // this.queryText = null;
      data = data.filter(x => !this.studentIds.includes(x.id));
      data.forEach((element) => {
        element.concatName = element.lastName;
        element.lausd = element.lausdId;
        element.concatName += element.middleName == null || element.middleName === '' ?
          ', ' + element.firstName :
          ', ' + element.firstName + ' ' + element.middleName;
      });
      console.log('fetchData(): data from service:', data);
      this.rows = data;
      this.filteredRows = data; // skip filtering on initial load
      // this.loading = false;
      console.log('fetchData(): done');
      // test out the loading bar (todo: remove later)
      const that = this;
      setTimeout(function () {
        that.loading = false;
      }, 1000);
    });
    //Vishnu: Not sure why this is called as the return observable is being not listened for any results.
    //this.schoolToStudentsService.getSchools();
  }
  // enrolls a student to an activity
  assignStudent(row) {
    console.log(row);
    this.studentActivity.scheduledActivityId = Number(this.activityId);
    this.studentActivity.studentId = row.id;
    this.studentActivity.schoolId = Number(this.schoolId);
    this.studentActivity.enrollmentDate = new Date();
    this.studentActivity.isActive = true;
    this.studentActivity.student = null;
    this.studentActivity.activity = null;
    console.log(this.studentActivity);
    this.studentsService.enrollStudent(this.studentActivity, this.modal);
  }
  // search function
  filterRows() {
    const safeQueryText = StringUtil.escapeSpecialCharacters(
      this.queryText.trim().toLowerCase()
    );
    console.log('filterRows(): safeQueryText: ', safeQueryText);
    this.filteredRows = this.rows.filter((row: any) => {
      if (!safeQueryText) {
        return true;
      }
      const filterRegExp = new RegExp(safeQueryText, 'i');

      for (const filterColumn of this.filterColumns) {
        const colValue = row[filterColumn];
        // toString() is needed if we want to search on numbers
        if (
          colValue &&
          colValue
            .toString()
            .toLowerCase()
            .match(filterRegExp)
        ) {
          return true;
        }
      }
      return false;
    });
    // Whenever the filter changes, always go back to the first page
    if (this.table !== undefined) {
      this.table.offset = 0;
    }
  }

  ngAfterViewChecked() {
    // When we resize the browser, or change the input rows for the datatable, the column widths
    // are re-calculated automatically. But, for some reason, when we refresh the page, the column
    // width recalculation fails causing some columns to not be displayed.
    // Workaround: Force the table to always recalculate after the view is checked.
    // See: https://github.com/swimlane/ngx-datatable/issues/1733
    this.recalculateData();
  }

  recalculateData() {
    // See: https://swimlane.gitbook.io/ngx-datatable/cd
    this.filteredRows = [...this.filteredRows];
  }

  ngOnInit() {
    this.fetchData();
    // this.calenderTypeDesc = this.calenderType == 1 ? "CORE" : "SUPP"
  }

  get profile() {
    return JSON.parse(window.localStorage.getItem('_ionicstorage/_ionickv/userSubject'));
  }

}
