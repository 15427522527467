import { Storage } from '@ionic/storage';
import { Observable, from, throwError } from 'rxjs';
import { Injectable } from '@angular/core';

import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpResponse,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { LoadingController, ToastController } from '@ionic/angular';
import { mergeMap, retry, map, catchError, tap, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class HttpRequestInterceptorService implements HttpInterceptor {
  indicator: HTMLIonLoadingElement = null;
  requestsQueue: Array<any> = [];
  errorShowing = false;
  selectedAgency = 0;
  constructor(
    private loading: LoadingController,
    private toastController: ToastController,
    private storage: Storage,
    private router: Router
  ) { }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const tokenPromise = this.storage.get('idToken');
    // var selectedAgency = localStorage["_ionicstorage/_ionickv/selectedAgencyId"];

    const asyncLocalStorage = {
      getItem: async function (key) {
        await null;
        return localStorage.getItem(key);
      }
    };

    let selectedAgency;
    // setTimeout(function () {
    asyncLocalStorage.getItem('_ionicstorage/_ionickv/selectedAgencyId').then(function (value) {
      selectedAgency = value;
    });
    // }, 1000);
    let username;
    asyncLocalStorage.getItem('_ionicstorage/_ionickv/username').then(function (value) {
      username = value;
    });

    const tokenObservable = from(tokenPromise);
    return tokenObservable.pipe(
      mergeMap((token: string) => {

        if (token === undefined || token === null || token === '') {
          this.storage.clear();
          window.localStorage.clear();
          this.router.navigate(['home']);
        }

        const customHeaders = {
        };
        if (token) {
          customHeaders['Authorization'] = `Bearer ${token}`;
        }

        //if(username){
        //  customHeaders['X-Username'] = username;
        //}else{
        //  console.log("no username");
        //}

        if (selectedAgency) {
          customHeaders['X-Agency'] = selectedAgency;
        }
        //customHeaders['Content-Type'] = 'application/json';

        const authorizedRequest = req.clone({
          headers: new HttpHeaders(customHeaders),
        });

        return next.handle(authorizedRequest).pipe(
          map(event => {
            if(event instanceof HttpErrorResponse || event instanceof HttpResponse ){
              console.log(event)
            }
            return event;
          }),
          catchError((err, caugth) => {
            if (err instanceof HttpErrorResponse) {
              if(err.status == 401){

              } // do the magic
            }
            return throwError(err);
          }),
          finalize(()=>{
            console.log('')
          }));
      })
    );
  }

  async showError() {
    if (!this.errorShowing) {
      this.errorShowing = true;
      this.toastController
        .create({
          message: 'Unable to complete your request.',
          cssClass: 'toast-danger',
          duration: 4000,
        })
        .then((toast) => {
          toast.present();
          toast.onDidDismiss().then(() => {
            this.errorShowing = false;
          });
        });
    }
  }


  showLoader() {
    if (this.indicator == null) {
      this.indicator = {} as HTMLIonLoadingElement;
      if (this.requestsQueue.length == 0) {
        this.loading
          .create({
            message: 'Please wait...',
            duration: 5000,
          })
          .then((res) => {
            this.indicator = res;
            res.present();

            // res.onDidDismiss().then((dis) => {
            // console.log("Loading dismissed!");
            // });
          });
      }
    }
    // this.requestsQueue.push({});
  }

  hideLoader() {
    this.requestsQueue.pop();
    if (this.requestsQueue.length == 0) {
      if (this.indicator != null || this.indicator != undefined) {
        if (this.indicator.dismiss) { this.indicator.dismiss(); }
      }
      this.indicator = null;
    }
  }
}
