// used on staff details page
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { StudentsService } from '../students/students.service';
import { Components } from '@ionic/core';
import { ActivitiesService } from '../activities/activities.service';
import { StaffService } from '../staff/staff.service';
import { PopoverController } from '@ionic/angular';
import { CancelConfirmationComponent } from '../cancel-confirmation/cancel-confirmation.component';
import * as m from 'moment';
@Component({
  selector: 'update-certification',
  templateUrl: './update-certification.component.html',
  styleUrls: ['./update-certification.component.scss'],
})
export class UpdateCertificationComponent implements OnInit {
  public certificationForm: FormGroup;
  objectKeys = Object.keys;
  loading = false;
  @Input() rolePermissionId;
  types: any;
  @Input() modal: Components.IonModal;
  showExpiration: boolean;

  constructor(public router: Router, public formBuilder: FormBuilder,
    private staffService: StaffService, public popoverController: PopoverController) {
    this.certificationForm = formBuilder.group({
      certId: ['', [Validators.required]],
      certType: ['', [Validators.required]],
      dateCompleted: ['', Validators.required]
    });
  }
  // fetch certifications
  fetchData() {
    this.loading = true;
    this.staffService.getTypes().subscribe((data: any) => {
      console.log('fetchData(): data from service:', data);
      // this.loading = false;
      console.log('fetchData(): done');
      this.types = data;
    });
  }

  async cancelConfirmation(ev: any) {
    const form = this.certificationForm.value;
    if (form.certType !== '' || form.dateCompleted !== '' || form.dateExpired !== '') {
      const popover = await this.popoverController.create({
        component: CancelConfirmationComponent,
        event: ev,
        translucent: true,
        componentProps: { modal: this.modal },
      });
      return await popover.present();
    } else {
      this.modal.dismiss('cancel');
    }
  }
  // expiration date input only shows for certifications that expire
  displayExpiration($event) {
    let cert = this.types.filter(x => x.name === this.certificationForm.value.certType)[0];
    this.certificationForm.get('certId').setValue($event[0].data.id);
    this.showExpiration = $event[0].data.certificateExpDays != null ? true : false;
    if (this.showExpiration) {
      this.certificationForm.addControl('dateExpired', new FormControl('', Validators.required));
    } else {
      this.certificationForm.removeControl('dateExpired');
    }
  }
  // add new certification to staff
  createCertification() {
    console.log(this.certificationForm);
    const cert = this.certificationForm.value;
    cert.dateExpired = this.showExpiration ? cert.dateExpired : null;
    cert.certName = cert.certType;
    cert.rolePermissionId = this.rolePermissionId;
    if (cert.dateExpired < cert.dateCompleted && cert.dateExpired) {
      this.certificationForm.setErrors(['Expiration date is invalid']);
    } else {
      this.staffService.createCertification(cert, this.modal).add(() => {
        this.modal.dismiss();
      });
    }
  }

  ngOnInit() {
    console.log(this);
    this.certificationForm.valueChanges.subscribe(change => {
      if (this.certificationForm.dirty) {
        this.modal.backdropDismiss = false;
      }
    });
    // tslint:disable-next-line: max-line-length
    this.fetchData();
  }
}

