import { Injectable } from '@angular/core';
import { SchoolsService } from '../../schools/schools.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalShareService {

  public allSchools: any[] | any = null;
  constructor(
    private schoolsService: SchoolsService
  ) { }

  getAllSchools() {
    var promise =  this.schoolsService.getSchools().toPromise();
    promise.then((response)=>{
      this.allSchools = [...response];
    });
    return promise;
  }

  getAllSchoolsByAgency(agencyId) {
    var promise =  this.schoolsService.getSchoolsByAgency(agencyId).toPromise();
    promise.then((response)=>{
      this.allSchools = [...response];
    });
    return promise;
  }

  getAllSchoolsByAgencyAndRole(agencyId, roleId) {
    var promise =  this.schoolsService.getSchoolsByAgencyAndRole(agencyId,roleId).toPromise();
    promise.then((response)=>{
      this.allSchools = [...response];
    });
    return promise;
  }
}
