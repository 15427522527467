// used on agency details page, assign-student-agency, schedule activity, school to students/staff, auth callback
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Storage } from '@ionic/storage';
import { ToastController } from '@ionic/angular';
import { idLocale } from 'ngx-bootstrap/chronos';

@Injectable({
  providedIn: 'root'
})
export class AgencyDetailsService {

  public idToken: string;

  constructor(public httpClient: HttpClient, public storage: Storage, public toastController: ToastController) { }
  // fetch agency data by ID
  getAgencyDetails(id: number) {
    console.log('getAgencyDetails() XXXXXXXX'); // todo: comment out logging as needed for prod
    return this.loadAgencyDetails(id).pipe(map(this.processAgencyDetails, this));
  }
  // fetch agency data by ID
  private loadAgencyDetails(id: number) {
    console.log('loadAgencyDetals() with url: ', environment.agenciesUrl + id);

    return this.httpClient.get(environment.agenciesUrl + id);
  }
  // fetch agency data by ID
  processAgencyDetails(data: any) {
    console.log('processAgencyDetails() with', data, 'rows.');
    data.status = data.isActive ? 'Active' : 'InActive';
    data.schools.forEach((row: any) => {
      if (row.schoolName) { //TBD
        row.ada2019 = '90%';
        row.ada2020 = '90%';
        row.totalenroll = '124';
        row.currtarget = '93%';
      }
    });
    return data;
  }

  setSelectedAgency(id: number) {
    console.log("Set selected agency with url:" + environment.ssoSetAgency + id);
    return this.httpClient.post(environment.agenciesUrl + "setagency/" + id, {});
  }

  getSelectedAgencyDetails() {
    console.log('getSelectedAgencyDetails()'); // todo: comment out logging as needed for prod
    return this.loadSelectedAgencyDetails().pipe(map(this.processAgencyDetails, this));
  }

  private loadSelectedAgencyDetails() {
    console.log('loadSelectedAgencyDetals() with url: ', environment.agenciesUrl + "selected");

    return this.httpClient.get(environment.agenciesUrl + "selected");
  }

  async showSuccess() {
    const toast = await this.toastController.create({
      color: 'success',
      duration: 2500,
      message: 'Success!',
      showCloseButton: true,
      position: 'top'
    });
    await toast.present();
    setTimeout(function () {
      // window.location.reload();
    }, 1500);
  }

  async showError(error) {
    console.log(error)
    const toast = await this.toastController.create({
      color: 'danger',
      duration: 2500,
      message: 'Error!',
      showCloseButton: true,
      position: 'top'
    });
    await toast.present();
  }
}
