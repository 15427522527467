import { TabsService } from './components/tabs.service';
import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, ActivationEnd, NavigationEnd } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { MenuController, Platform, ToastController, ModalController, LoadingController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Storage } from '@ionic/storage';
import { UserData } from './users/user-data';
import { AuthServiceProvider } from './users/auth-service/auth-service';
import { AgencyDetailsService } from './agency-details/agency-details.service';
import { environment } from '../environments/environment';
import { Subject } from 'rxjs';
import { query } from '@angular/animations';
import { AwardDetailsService } from './award-details/award-details.service';
import { ModalComponent, ModalAlertComponent } from './modal-alert/modal-alert.component';
import { ReportsService } from './reports/reports.service';
import { RolePermissionsService } from './users/rolepermissions-service/rolepermissions-service';
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(
    private menu: MenuController,
    private platform: Platform,
    private router: Router,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    public fundingDetailsService: AwardDetailsService,
    public userData: UserData,
    private toastCtrl: ToastController,
    private authService: AuthServiceProvider,
    private agencyDetailsService: AgencyDetailsService,
    public reportsService: ReportsService,
    private rolePermissionsService: RolePermissionsService,
    public tc: TabsService,
    private activeRoute: ActivatedRoute,
    private modalController: ModalController,
    private menuController: MenuController,
    private loading: LoadingController
  ) {
    this.userSet = this.userData;
    console.log('initializeApp() >>>>>>>>>>>>>>>>>>>>>>>>>>>>>.');
    this.initializeApp();
  }

  selectedMenu = '';
  appPages = [
    {

      title: 'Dashboard',
      url: '/dashboard',
      icon: 'briefcase',
      allowedRoles: [1, 2, 3, 4, 5, 6, 7, 8]
    },
    {

      title: 'Funding',
      url: '/funding',
      icon: 'cash',
      allowedRoles: [1, 2, 3, 4, 5, 6, 7, 8]

    },

    {

      title: 'Schools',
      url: '/schools',
      icon: 'school',
      allowedRoles: [1, 2, 3, 4, 5, 6, 7, 8],
    },
    {
      title: 'Students',
      url: '/school-students',
      icon: 'people',
      allowedRoles: [4, 6, 7, 8],
    },
    {
      title: 'Activities',
      url: '/activities',
      icon: 'football',
      allowedRoles: [6],
    },
    {

      title: 'Agencies',
      url: '/agencies',
      icon: 'star',
      allowedRoles: [1, 2, 3, 4, 5],
    },
    {

      title: 'Staff',
      url: '/school-staff',
      icon: 'person',
      allowedRoles: [1, 2, 3, 4, 5, 6, 7, 8],
    },
    {

      title: 'Reports',
      url: '/reports',
      icon: 'document',
      allowedRoles: [1, 2, 3, 4, 5, 6, 7, 8],
    },
    {

      title: 'Admin',
      url: '/master-calendar',
      icon: 'settings',
      allowedRoles: [1, 2, 3, 4, 5],
    }
    // {

    //   title: 'Settings',
    //   url: '/app/tabs/settings',
    //   icon: 'settings',
    //   allowedRoles: [1, 2, 3, 4, 5, 6, 7, 8],
    // },
  ];
  loggedIn = false;
  dark = false;
  userName = '';
  dropdownModel: string;
  agencyName = '';
  currentFiscalYear = '';
  currentCalendarYear: any;
  currentDate: any;
  roles: any;
  roles1to5: any = [1, 2, 3, 4, 5];
  userSet: any;
  sessionTimeout: any = 0;
  pageTabs: any = null;
  // page title
  currentId: any = null;
  unauthorized = false;
  roleName = '';
  separator = '';
  indicator: HTMLIonLoadingElement = null;
  requestsQueue: Array<any> = [];
  menuOpen = true;

  ngAfterViewInit() {
    this.initGoogleAnalyticsPageView();
  }

  private initGoogleAnalyticsPageView() {
    this.showLoader();
    const interval = setInterval(() => {
      if ((window as any).ga && (window as any).ga.getAll) {
        this.router.events.subscribe(event => {
          const ga = (window as any).ga;
          if (event instanceof NavigationEnd) {
            const tracker = ga.getAll()[0];
            tracker.set('page', event.urlAfterRedirects);
            tracker.send('pageview');
          }
        });
        clearInterval(interval);
      }
    }, 50);
    this.hideLoader();
  }

  async ngOnInit() {
    this.showLoader();
    const self = this;
    this.userData.isLoggedIn().then((isLogged) => {

      if (isLogged) {
        this.roles = this.userData;
        this.fetchCurrentMasterCalendar();
        this.checkLoginStatus();
        this.listenForLoginEvents();
        this.getDateComponentInfo();

        // session handler
        this.sessionTimeoutObserver();
        this.listenForRouterEvents();
        // this.router.navigateByUrl('/dashboard');
      } else {
        console.log('isLogged false case ==========');
        this.router.navigateByUrl('/');
      }
    });
    this.hideLoader();
  }

  fetchCurrentMasterCalendar(){
    this.reportsService.setCurrentMasterCalendar().subscribe((data: any) => {
      console.log('fetchMasterCalendars(): data from calendarYear service:', data);
    });
  }

  listenForRouterEvents() {
    this.router.events.subscribe(e => {
      // console.log(e);
      if (e instanceof ActivationEnd) {
        this.router.events.subscribe(event => {
          if (event instanceof NavigationEnd) {
            (<any>window).ga('set', 'page', event.urlAfterRedirects);
            (<any>window).ga('send', 'pageview');
          }
        });
        if (e.snapshot.data) {
          this.unauthorized = e.snapshot.data.title === 'Unauthorized';
          const page = this.appPages.find(x => x.title === e.snapshot.data.parent);
          this.selectedMenu = page != null ? page.title : this.selectedMenu;

          if (e.snapshot.data.title) {
            environment.pageTitle = e.snapshot.data.title;
            environment.pageSubTitle = e.snapshot.data.subTitle;
            this.currentId = e.snapshot.params.id;
            console.log(e.snapshot, '~~~~~~~~~~')
          }
          if (e.snapshot.data.tabs) {
            this.pageTabs = e.snapshot.data.tabs;
            //if admin, remove attendance from schools page
            if(this.userData.hasRoles([1,2,3,4,5]) && e.snapshot.data.parent == 'Schools'){
              this.pageTabs = this.pageTabs.filter(tab => tab.name != 'Attendance')
            }
            // tslint:disable-next-line: prefer-const
            let first = this.pageTabs.find(t => t.selected);
            this.tc.tabChannel = first == null ? this.pageTabs[0].path : first.path;

          } else {
            this.pageTabs = [];
          }
        }
      }
    });
  }
  customRoute(tab) {
    if (tab.customRoute) {
      const route = tab.customRoute.replace('{id}', this.currentId);
      this.router.navigateByUrl(route).then(() => {
        this.tc.tabChannel = tab.path;
      });

    }
  }
  sessionTimeoutObserver() {
    this.router.events.subscribe((routeEvent) => {
      this.sessionTimeout = 0;
    });
    setInterval(() => {
      if (this.sessionTimeout >= environment.sessionTimeout) {
        this.forceLogout(); // sd
      } else {
        this.sessionTimeout = this.sessionTimeout + 6000;
        console.log(`session timeoute:${this.sessionTimeout}`);
      }
    }, 6000);
  }
  toggle() {
    this.menuOpen = !this.menuOpen;
  }
  resize(event) {
    if (window.screen.width <= 991) {
      this.menuController.close();
      event.stopPropagation();
    } else {
      if (document.getElementById('resizable-nav').getAttribute('small') === 'false') {
        document.getElementById('resizable-nav').setAttribute('small', 'true');
        document.getElementById('logo').style.cssText = 'display: none; transition: 0.3s;';
        document.getElementById('menu-h').style.cssText = 'position: relative;left: 7px; transition: 0.3s;';
        document.getElementById('main-title').style.cssText = 'padding-inline-start: 0px; padding-inline-end: 0px;transition: 0.3s;';
        document.getElementById('menu-h-title').style.cssText = 'transition: 0.3s;display:none; white-space: pre;';
        document.getElementById('hamburger').style.cssText = 'left: 2px;position: relative;transition: 0.3s;';
        document.getElementById('resizable-nav').style.cssText =
          'min-width: 80px !important; max-width: 80px !important; transition: 0.3s;';
        document.getElementById('agency-text').style.cssText = 'display: none;transition: 0.3s;';
        document.querySelectorAll('ion-menu')[0].querySelectorAll('ion-label').forEach(x => {
          x.style.cssText = 'display:none; transition: 0.3s;';
        });
        document.querySelectorAll('ion-menu')[0].querySelectorAll('ion-icon').forEach(x => {
          x.style.cssText = 'margin-left: 5px; transition: 0.3s;min-width: 43%;';
        });
        // document.querySelectorAll('ion-menu')[0].querySelectorAll('ion-icon').forEach(x => {
        //   x.style.cssText = 'margin-left: 30px;';
        // });
      } else {
        document.getElementById('resizable-nav').setAttribute('small', 'false');
        document.querySelectorAll('ion-menu')[0].querySelectorAll('ion-icon').forEach(x => {
          x.style.cssText = 'transition: 0.3s;';
        });
        document.querySelectorAll('ion-menu')[0].querySelectorAll('ion-label').forEach(x => {
          x.style.cssText = '';
        });
        document.getElementById('logo').style.cssText = 'transition: 0.3s;';
        document.getElementById('menu-h-title').style.cssText = 'transition: 0.3s;white-space: pre;';
        document.getElementById('hamburger').style.cssText = '';
        document.getElementById('resizable-nav').style.cssText = 'transition: 0.3s;';
        document.getElementById('menu-h').style.cssText = 'transition: 0.3s;white-space: pre;';
        document.getElementById('agency-text').style.cssText = 'transition: 0.3s;';
        document.getElementById('main-title').style.cssText = 'transition: 0.3s;white-space: pre;';
      }
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  checkLoginStatus() {
    return this.userData.isLoggedIn().then((loggedIn) => {
      return this.updateLoggedInStatus(loggedIn);
    });
  }

  updateLoggedInStatus(loggedIn: boolean) {

    setTimeout(() => {
      this.loggedIn = loggedIn;
      this.userData.getTokenProfile().then((res: any) => {
        if (res !== null) {
          this.userName = `${res.given_name} ${res.family_name}`;
          console.log('updateLoggedInStatus() >>>>>>>', res);
        }
      });
      this.userData.getProfile().then((profile: any) => {
        console.log('this.userData.getProfile()', profile);
        if (profile != null) {
          this.getAgencyName(profile.agency);
          this.getRoleName(profile.roles);
        }
      });
    }, 300);
  }

  getAgencyName(agency) {
    console.log('getAgencyName(agency)', agency);
    this.agencyDetailsService.getAgencyDetails(agency).subscribe((data: any) => {
      console.log('getAgencyName(): data from agencyDetailsService:', data);
      this.agencyName = data.name;
    });
  }

  getRoleName(roleid) {
    this.rolePermissionsService.getAllRoles().subscribe((data: any) => {
      console.log('getAllRoles(): data from rolePermissionsService:', data);
      this.roleName = data.filter(x => x.id == roleid)[0].name;
    });
  }


  async openMenu() {
    await this.menuController.open();
    document.getElementById('resizable-nav').setAttribute('small', 'false');
    document.querySelectorAll('ion-menu')[0].querySelectorAll('ion-icon').forEach(x => {
      x.style.cssText = 'transition: 0.3s;';
    });
    document.querySelectorAll('ion-menu')[0].querySelectorAll('ion-label').forEach(x => {
      x.style.cssText = '';
    });
    document.getElementById('logo').style.cssText = 'transition: 0.3s;';
    document.getElementById('menu-h-title').style.cssText = 'transition: 0.3s;white-space: pre;';
    document.getElementById('hamburger').style.cssText = '';
    document.getElementById('resizable-nav').style.cssText = 'transition: 0.3s;';
    document.getElementById('menu-h').style.cssText = 'transition: 0.3s;white-space: pre;';
    document.getElementById('agency-text').style.cssText = 'transition: 0.3s;';
    document.getElementById('main-title').style.cssText = 'transition: 0.3s;white-space: pre;';
  }

  listenForLoginEvents() {
    console.log('listenForLoginEvents()');
    window.addEventListener('user:login', () => {
      console.log('user:login');
      this.updateLoggedInStatus(true);
      this.userData.getProfile().then((profile: any) => {
        const item = {
          title: 'Funding',
          url: '/awards/',
          icon: 'cash',
          allowedRoles: [6, 7, 8],
        };

        const adminRoles: number[] = [1, 2, 3, 4, 5];
        // tslint:disable-next-line: triple-equals
        //  if(!adminRoles.includes(profile.roles[0]) && profile.agencyType != 'Internal') {
        //    this.appPages.splice(8, 1);
        //  }
        // this.appPages.splice(1, 0, item);
      });
    });

    window.addEventListener('user:signup', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:logout', () => {
      this.updateLoggedInStatus(false);
    });
  }

  async forceLogout(){
    this.authService.signOut();
  }
  async logout() {
    // console.log('logout()', this.dropdownModel);
    if (this.dropdownModel == 'Logout') {
      const options: ModalComponent = {
        header: 'Logout',
        body: 'Are you sure you want to log out?',
        model: { canLogout: true }
      };

      const modal = await this.modalController.create({
        component: ModalAlertComponent,
        componentProps: {
          props: options
        }
      });

      modal.onDidDismiss().then(({ data }) => {
        if (data.model) {
          const { canLogout } = data.model;
          if (canLogout) {
            this.userData.logout().then(() => {
              this.authService.signOut();
            });
          }
        }
        this.dropdownModel = null;
      });
      return await modal.present();
    } else if (this.dropdownModel == 'About Me') {
      const options: ModalComponent = {
        header: 'About Me',
        body: 'Version: ' + environment.appVersion,
        okText: 'Ok',
        messageDialog: true,
        model: {}
      };

      const modal = await this.modalController.create({
        component: ModalAlertComponent,
        componentProps: {
          props: options
        }
      });
      modal.onDidDismiss().then(({ data }) => {
        this.dropdownModel = null;
      });
      return await modal.present();
    } else if (this.dropdownModel == 'User Guide') {
      window.open('https://ihi.gitbook.io/btb-user-guide/', '_blank')
      this.dropdownModel = null;
    }
  }

  getRouteTitle(): string {
    return environment.pageTitle;
  }
  getRouteSubTitle(): string {
    return environment.pageSubTitle;
  }
  getTopNavBarSegments(id) {
    const ids = [
      'activities-segment',
      'students-segment',
      'schools-segment',
      'staff-segment',
      'awards-segment',
    ];
    if (ids.includes(id)) {
      const elToAdd = document.getElementById(id);
      if (elToAdd === undefined) {
        return;
      }
      if (elToAdd.parentElement.id == 'nav') { return; }
      const elToRemove = document.querySelectorAll('#nav ion-segment');
      if (elToRemove.length > 0) {
        elToRemove.forEach((el) => {
          el.remove();
        });
      }
      elToAdd.style.width = '33%';
      elToAdd.style.margin = 'auto';
      elToAdd.style.paddingTop = '20px';
      elToAdd.style.paddingLeft = '10px';
      document.getElementById('nav').appendChild(elToAdd);
    } else {
      const elToRemove = document.querySelectorAll('#nav ion-segment');
      if (elToRemove === undefined) {
        return;
      }
      if (elToRemove.length > 0) {
        elToRemove.forEach(el => {
          el.remove();
        });
      }
    }
  }

  getDateComponentInfo() {

    this.reportsService.getFiscalYears().subscribe((data: any) => {
      console.log('getFiscalYears(): data from getFiscalYears service:', data);
      this.currentFiscalYear = data.filter(x => x.isCurrent)[0].description;
      this.currentFiscalYear = this.currentFiscalYear.slice(0, 2) + ' ' + this.currentFiscalYear.slice(2);
    });
    this.reportsService.getCalendarYears().subscribe((data: any) => {
      console.log('getFiscalYears(): data from getFiscalYears service:', data);
      this.currentCalendarYear = data.filter(x => x.isCurrent)[0].description;
      this.currentCalendarYear = this.currentCalendarYear.slice(0, 2) + ' ' + this.currentCalendarYear.slice(2);
    });
    this.currentDate = moment().format('MMMM Do YYYY');
    this.separator = ',';
  }

  getTitle() {
    let title = window.location.href.split('/').pop();
    if (!isNaN(Number(title))) {
      const arr = window.location.href.split('/');
      title = arr[arr.length - 2];
      if (!isNaN(Number(title))) {
        title = arr[arr.length - 3];
      }
    }

    switch (title) {
      // Activities
      case 'activities':
        this.getTopNavBarSegments('false');
        return 'Activities';
      case 'activity-details':
        this.getTopNavBarSegments('activities-segment');
        return 'Activities';
      case 'activity':
        this.getTopNavBarSegments('false');
        return 'Activity';
      // Funding
      case 'funding':
        this.getTopNavBarSegments('false');
        return 'Funding';

      case 'dashboard':
        this.getTopNavBarSegments('false');
        return 'Dashboard';

    }
  }

  removeTabOption(route: ActivatedRoute, pathName: string) {
    this.pageTabs = route.snapshot.data.tabs.filter(x => x.path !== pathName);
  }

  showLoader() {
    if (this.indicator == null) {
      this.indicator = {} as HTMLIonLoadingElement;
      if (this.requestsQueue.length == 0) {
        this.loading
          .create({
            message: 'Please wait...',
            duration: 2000,
          })
          .then((res) => {
            this.indicator = res;
            res.present();

            // res.onDidDismiss().then((dis) => {
            // console.log("Loading dismissed!");
            // });
          });
      }
    }
    // this.requestsQueue.push({});
  }

  hideLoader() {
    this.requestsQueue.pop();
    if (this.requestsQueue.length == 0) {
      if (this.indicator != null || this.indicator != undefined) {
        if (this.indicator.dismiss) { this.indicator.dismiss(); }
      }
      this.indicator = null;
    }
  }
}
