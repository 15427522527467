// used on student details
import { Component, OnInit, ViewChild, ChangeDetectionStrategy, Input, HostListener } from '@angular/core';
import { StringUtil } from '../shared/string-util';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { ActivitiesService } from '../activities/activities.service';
import { StudentsService } from '../students/students.service';
import { StudentAttendanceService } from '../student-attendance/student-attendance.service';
import { Components } from '@ionic/core';
import { ModalController, PopoverController, AlertController } from '@ionic/angular';
import * as moment from 'moment';
import { UserData } from '../users/user-data';
import { Authentication, User } from 'adal-ts';
import { AuthServiceProvider } from '../users/auth-service/auth-service';
import { StaffService } from '../staff/staff.service';
@Component({
  changeDetection: ChangeDetectionStrategy.Default,
  selector: 'student-attendance',
  templateUrl: './student-attendance.component.html',
  styleUrls: ['./student-attendance.component.scss'],
})
export class StudentAttendanceComponent implements OnInit {
  @Input() student;
  @Input() schoolIdContext;
  @Input() attendancetype;
  @Input() caltype;
  @Input() calid;
  @Input() modal: Components.IonModal;
  activities: any;
  caltypeText: any;
  loading = false;
  studentActivity: any = {};
  queryText = '';
  rows: any = [];
  filteredRows: any = [];
  selected = [];
  columnMode = ColumnMode.force;
  selectionType = SelectionType.single;
  days: any = ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat'];
  columns = [
    { name: 'Activity Name', prop: 'activity.activityName' },
    { name: 'Group', prop: 'groupName' },
    { name: 'School', prop: 'school.schoolName' },
    { name: 'Start / End Time', prop: 'startEndTime' },
    { name: 'Days of the Week', prop: 'daysOfWeek' }
  ];
  filterColumns = ['activity.activityName', 'school.schoolName', 'startEndTime', 'daysOfWeek'];
  defaultSort = [{ prop: 'lastName', dir: 'asc' }];
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  innerWidthGreater: boolean;
  user: User;

  constructor(private activitiesService: ActivitiesService, public studentsService: StudentsService,
    public popoverController: PopoverController,
    public modalController: ModalController,
    public alertController: AlertController,
    public studentAttendanceService: StudentAttendanceService,
    public staffService: StaffService,
    public userData: UserData,
    private authService: AuthServiceProvider
  ) { }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidthGreater = window.innerWidth <= 728;
  }
  ngOnInit() {
    console.log("ngOnInit of StudentAttendanceComponent >>>>>>>")
    console.log(this.schoolIdContext)
    console.log(this.student)
    this.innerWidthGreater = window.innerWidth <= 728;
    if(this.caltype == 1){
      this.caltypeText = "CORE";
    }
    if(this.caltype == 2){
      this.caltypeText = "SUPP";
    }
    this.fetchData();
  }
  // fetch available scheduled activities
  fetchData() {
    this.loading = true;
    console.log(this.student.id, Number(this.schoolIdContext));
    this.activitiesService.getStudentActivitiesForAgency(this.student.id, Number(this.schoolIdContext)).subscribe((data: any) => {
      console.log('fetchData() - nonenrolledscheduledactivities : data from service:', data);
      data.forEach(element => {
        element.daysOfWeek = '';
        // element.name = element.activity.activityName + ' - ' + element.groupName;
        element.startEndTime = moment(element.startDate).format('MM/DD/YYYY') + ' - ' + moment(element.endDate).format('MM/DD/YYYY');
        const daysOfWeekArr = [];
        let count = 0;
        element.daysofOperation.forEach(x => {
          if (x != null) {
            daysOfWeekArr[count] = this.days[x];
            count++;
          }
        });
        element.daysOfWeek = daysOfWeekArr.join(', ');
      });

      if(this.calid > 0 && this.caltypeText !== undefined && this.caltypeText.length > 0){
        this.filteredRows = data.filter(x => x.calendarId == this.calid && x.calendarType == this.caltypeText);
        this.rows = data.filter(x => x.calendarId == this.calid && x.calendarType == this.caltypeText);
      }
      else{
        this.filteredRows = data;
        this.rows = data;
      }
      // this.loading = false;
      console.log('fetchData(): done');

      // test out the loading bar (todo: remove later)
      const that = this;
      setTimeout(function () {
        that.loading = false;
      }, 1000);
    });
  }


  async AddStudentAttendance(row:any) {
    console.log('AddStudentAttendance : ', row);
    console.log('AddStudentAttendance student : ', this.student);

    this.userData.getTokenProfile().then((data: any) => {
      if (data !== null) {
        let user = data;
        console.log(user)
        this.staffService.getStaffInfoByLoginName(user.unique_name).subscribe(
          (res:any)=>{
            console.log(res);
            let staff  = res.staff.find(x => x.staff.schoolId == this.schoolIdContext).staff;
            console.log(staff)

            if(this.attendancetype === 'Check-In'){
              const requestBody = {
                studentId: this.student.id,
                attendanceType: 1,
                authorizedPersonPIN: 'SAOverride',
                staffId: staff.id,
                scheduleActivityId: row.id,
                codeId: ''
              }
              console.log('AddStudentAttendance student : ' + this.attendancetype, requestBody);
              this.studentAttendanceService.studentAttendance(requestBody).add(() => {
                row.isAlreadyAdded = true;
                this.modal.dismiss();
              });
            }

            if(this.attendancetype === 'CheckOut'){

              const requestBody = {
                studentId: this.student.id,
                attendanceType: 3,
                scheduledActivityId: 0,
                staffId: staff.id,
                authorizedPersonPIN: 'SAOverride',
                codeId: ''
              }
              console.log('AddStudentAttendance student : ' + this.attendancetype, requestBody);
              this.studentAttendanceService.studentAttendance(requestBody).add(() => {
                this.modal.dismiss();
              });
            }

            if(this.attendancetype === 'Transfer'){

              const requestBody = {
                studentId: this.student.id,
                attendanceType: 2,
                scheduleActivityId: row.id,
                staffId: staff.id,
                authorizedPersonPIN: 'SAOverride',
                codeId: ''
              }
              console.log('AddStudentAttendance student : ' + this.attendancetype, requestBody);
              this.studentAttendanceService.studentAttendance(requestBody).add(() => {
                //this.modal.dismiss();
                row.isAlreadyAdded = true;
                this.modal.dismiss();
              });
            }

          }
        );

      }
    });



    return await null; //this.webApi.postAsync(url, requestBody).toPromise();
  }

  async TransferStudent(id: any, pin: any, activityId: any, staffId: any) {
    const url = ''//`${StudentsService.API_POSTSTUDENTCHECKIN}`;
    const requestBody = {
      studentId: id,
      attendanceType: 2,
      authorizedPersonPIN: pin,
      staffId: staffId,
      activityId: activityId
    }

    return await null;//this.webApi.postAsync(url, requestBody).toPromise();
  }

  async CheckOutStudent(
    studentId: any,
    checkoutProvider: string,
    checkoutResponse: Object,
    personId: string,
    staffId: any,
    codeId: string
  ) {
    const userId = studentId;
    let personPin = null;
    let personSign = null;
    if(checkoutProvider == "pin"){
      personPin = checkoutResponse;
    }else{
      personSign = checkoutResponse;
    }

    const requestBody = {
      studentId: userId,
      attendanceType: 3,
      scheduledActivityId: 0,
      staffId: staffId,
      authorizedPersonPIN: personPin,
      AuthorizedPersonId: personId,
      AuthorizedPersonSignB64: personSign,
      codeId: codeId
    }
    const url = '';// `${StudentsService.API_POSTSTUDENTCHECKIN}`;
    return await null ;//this.webApi.postAsync(url, requestBody).toPromise();
  }


  checkoutstudent() {
    console.log("Student CheckedOUt successfully.");
    this.modal.dismiss('cancel');
  }


  async confirmStudentAttendance(row: any) {


    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Confirm Student ' + this.attendancetype,
      message: 'Are you sure you would like to '+ this.attendancetype +' this student?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Yes',
          handler: () => {
            console.log('Confirm Okay');
            this.onSelect(row);
          }
        }
      ]
    });

    await alert.present();
  }


  onSelect(row) {
    console.log('Select Event ', row);
    row = row.selected[0];
    console.log(row);
    this.studentActivity.scheduledActivityId = row.id;
    this.studentActivity.studentId = this.student.id;
    this.studentActivity.schoolId = row.schoolId;
    this.studentActivity.enrollmentDate = new Date();
    this.studentActivity.isActive = true;
    this.studentActivity.student = null;
    this.studentActivity.activity = null;
    console.log(this.studentActivity);
    this.studentsService.enrollStudent(this.studentActivity, this.modal).add(() => {
      this.modal.dismiss();
    });
  }

  filterRows() {
    const safeQueryText = StringUtil.escapeSpecialCharacters(
      this.queryText.trim().toLowerCase()
    );
    // console.log('filterRows(): safeQueryText: ', safeQueryText);
    this.filteredRows = this.rows.filter((row: any) => {
      if (!safeQueryText) {
        return true;
      }
      for (const filterColumn of this.filterColumns) {
        let colValue = row[filterColumn];
        if (filterColumn.includes('activity')) {
          colValue = row['activity'].activityName;
        } else if (filterColumn.includes('school')) {
          colValue = row['school'].schoolName;
        }
        // toString() is needed if we want to search on numbers
        if (
          colValue &&
          colValue
            .toString()
            .toLowerCase()
            .match(safeQueryText)
        ) {
          return true;
        }
      }
      return false;
    });
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  ngAfterViewChecked() {
    // When we resize the browser, or change the input rows for the datatable, the column widths
    // are re-calculated automatically. But, for some reason, when we refresh the page, the column
    // width recalculation fails causing some columns to not be displayed.
    // Workaround: Force the table to always recalculate after the view is checked.
    // See: https://github.com/swimlane/ngx-datatable/issues/1733
    this.recalculateData();
  }

  recalculateData() {
    // See: https://swimlane.gitbook.io/ngx-datatable/cd
    this.filteredRows = [...this.filteredRows];
  }
}
