// used on awards page and award details page
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Components } from '@ionic/core';
import { AwardsService } from '../awards/awards.service';
import { PopoverController } from '@ionic/angular';
import { SchoolsService } from '../schools/schools.service';
import { FundingDetailsService } from '../funding-details/funding-details.service';
import { FundingService } from '../funding/funding.service';
import { DeactivateAwardComponent } from '../deactivate-award/deactivate-award.component';
import { awardType } from '../awards/awardType.enum';
import { environment } from '../../environments/environment';
import _ from 'lodash';
import { CancelConfirmationComponent } from '../cancel-confirmation/cancel-confirmation.component';
import { GlobalShareService } from '../core/services/global-share.service';
// import { DeactivateAgencyComponent } from '../deactivate-agency/deactivate-agency.component';
@Component({
  selector: 'update-award',
  templateUrl: './update-award.component.html',
  styleUrls: ['./update-award.component.scss'],
})
export class UpdateAwardComponent implements OnInit {
  public awardForm: FormGroup;
  loading = false;
  @Input() update;
  @Input() award;
  @Input() fundingSourceId;
  agencies: any;
  @Input() modal: Components.IonModal;
  schools: any;
  funders: any;
  schoolName: any;
  showCohort: any;
  objectKeys = Object.keys;
  types = awardType;
  get sortedSchools() {
    return _.sortBy(this.schools, ['schoolName']);
  }
  constructor(public router: Router, public formBuilder: FormBuilder,
    private awardService: AwardsService, private fundingService: FundingService,
    public popoverController: PopoverController, private schoolsService: SchoolsService,
    private globalShareService: GlobalShareService
  ) {
    this.awardForm = formBuilder.group({
      schoolId: [''],
      fundingSourceId: [this.fundingSourceId],
      dooType: ['', Validators.required],
      awardAmount: ['', [Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
      stateRate: ['', Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')],
      type: ['', Validators.required],
      priorityGoal: [85, Validators.required]
    });
  }
  // cancel award creation
  async cancelConfirmation(ev: any) {
    const form = this.awardForm.value;
    if (form.schoolId !== '' || form.awardId !== ''
      || form.awardAmount !== '' || form.stateRate !== '' || form.type !== '') {
      const popover = await this.popoverController.create({
        component: CancelConfirmationComponent,
        event: ev,
        translucent: true,
        componentProps: { modal: this.modal },
      });
      return await popover.present();
    } else {
      this.modal.dismiss('cancel');
    }
  }
  // update existing award
  updateAward() {
    console.log(this.awardForm.value);
    this.award.award.schoolId = this.awardForm.value.schoolId;
    this.award.award.school.id = this.awardForm.value.schoolId;
    this.award.award.priorityGoal = this.awardForm.value.priorityGoal;
    this.award.award.dooType = this.awardForm.value.dooType;
    this.award.award.agencyId = parseInt(this.profile.agency);
    this.award.award.school = null;
    this.award.award.agencies = null;
    this.award.award.fundingSource = null;
    this.award.award.awardId = 0;
    this.award.unearned = null;
    this.award.award.awardAmount = parseFloat(this.awardForm.value.awardAmount);
    this.award.award.stateRate = parseFloat(this.awardForm.value.stateRate);
    this.award.award.type = parseInt(this.awardForm.value.type);
    this.award.award.awardAmounts = [];
    this.awardService.updateAward(this.award.award).add(() => {
      this.modal.dismiss();
    });
  }
  // create new award
  async createAward() {
    console.log('awardForm ' + this.awardForm);
    const award = this.awardForm.value;
    award.awardAmount = parseFloat(award.awardAmount);
    // tslint:disable-next-line: radix
    award.awardId = 0; // parseInt(award.awardId);
    award.agencyId = parseInt(this.profile.agency);
    award.stateRate = parseFloat(this.awardForm.value.stateRate);
    award.dailyTarget = 0;
    award.isActive = true;
    award.unearned = 0;
    // award.grantId = this.awardForm.value.grantId.toString();
    award.schoolId = parseInt(award.schoolId);
    award.source = 'BTB';
    award.fundingSourceId = parseInt(this.fundingSourceId);
    award.type = parseInt(award.type);
    award.dooType = parseInt(award.dooType);
    this.awardService.createAward(award, this.modal).subscribe(() => {
      this.modal.dismiss();
    });
    console.log(award.unearned);
  }

  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: DeactivateAwardComponent,
      event: ev,
      translucent: true,
      componentProps: { awardId: this.award.award.id },
    });
    popover.onDidDismiss().then(() => {
      this.modal.dismiss();
    });
    return await popover.present();
  }

  populateAward = async () => {
    const data = [...this.globalShareService.allSchools];
    data.forEach(x => {
      x.customText = x.schoolName + ' - ' + x.cdsCode;
    });
    this.schools = data;

    if (this.update) {
      this.awardForm.patchValue(this.award.award);
      this.awardForm.get('stateRate').setValue(Number(this.awardForm.value.stateRate.replace(/[^0-9.-]+/g, '')));
      this.awardForm.get('type').setValue(this.award.award.type);
      if (this.award.award.school) {
        this.awardForm.get('schoolId').setValue(this.award.award.school.id);
      }
    }
  }

  selectionChanged() {
    console.log(this.awardForm.value.schoolId);
  }

  get profile() {
    return JSON.parse(window.localStorage.getItem('_ionicstorage/_ionickv/userSubject'));
  }

  ngOnInit() {
    console.log(this);
    this.awardForm.statusChanges.subscribe(change => {
      if (this.awardForm.dirty) {
        this.modal.backdropDismiss = false;
      }
    });
    this.populateAward();
  }
}

