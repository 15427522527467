import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router'
import { Observable } from 'rxjs'
import { GlobalShareService } from './../services/global-share.service';

@Injectable({
  providedIn: 'root'
})

export class AllSchoolsResolver implements Resolve<any> {

  constructor(
    private globalShareService: GlobalShareService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<any> | Promise<any> | any {
    return new Promise((resolve,reject)=>{
      if (!this.globalShareService.allSchools) {
        if(this.hasRoles([6])){
          this.globalShareService.getAllSchoolsByAgency(this.profile.agency).then(()=>{
            resolve(true);
          });
        } else if(this.hasRoles([7,8])){
          this.globalShareService.getAllSchoolsByAgencyAndRole(this.profile.agency, this.profile.roles[0]).then(()=>{
            resolve(true);
          });
        } else{
          this.globalShareService.getAllSchools().then(()=>{
            resolve(true);
          });
        }
      }else{
        resolve(true);
      }
    });
  }

  get profile() {
    return JSON.parse(window.localStorage.getItem('_ionicstorage/_ionickv/userSubject'));
  }

  hasRoles(roles: number[]): boolean {
    if (this.profile) {
      for (const aRole of this.profile.roles) {
        if (roles.includes(aRole)) {
          return true;
        }
      }
    }
    return false;
  }
}
