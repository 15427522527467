import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, convertToParamMap } from '@angular/router';
import { StudentsService } from '../students/students.service';
import { Components } from '@ionic/core';
import { ActivitiesService } from '../activities/activities.service';

@Component({
  selector: 'update-activity',
  templateUrl: './update-activity.component.html',
  styleUrls: ['./update-activity.component.scss'],
})
export class UpdateActivityComponent implements OnInit {
  public activityForm: FormGroup;
  objectKeys = Object.keys;
  loading = false;
  @Input() update;
  @Input() activity;
  @Input() agencyId;
  codes: any = [];
  code: any;
  @Input() modal: Components.IonModal;

  constructor(public router: Router, public formBuilder: FormBuilder,
    private activityService: ActivitiesService) {
    this.activityForm = formBuilder.group({
      activityName: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9 ]*$')]],
      enrichmentCode: ['', Validators.required]
    });
  }
  // fetch enrichment codes
  fetchData() {
    this.loading = true;
    this.activityService.getCodes().subscribe((data: any) => {
      console.log('fetchData(): data from service:', data);
      // this.loading = false;
      console.log('fetchData(): done');
      this.objectKeys(data).forEach(x => {
        this.codes.push({ 'key': data[x].code, 'text': data[x].code + ' - ' + data[x].description });
      });
      console.log(this.codes);
      this.code = this.activity ? this.activity.enrichmentCode + ' - ' + this.codes[this.activity.enrichmentCode] : null;
      if (this.update) {
        this.populateActivity();
      }
    });
  }
  // resize modal to accomodate dropdown
  onClickDropDown() {
    document.getElementById('dropdowndiv').style.height = '175px';
  }
  // resize modal to accomodate dropdown
  selectionChanged() {
    document.getElementById('dropdowndiv').style.height = '50px';
  }
  // create new activity
  createActivity() {
    console.log(this.activityForm);
    const activity = this.activityForm.value;
    activity.isActive = true;
    activity.notes = 'test';
    console.log('AgencyId:', this.agencyId);
    activity.agencyId = this.agencyId;
    this.activityService.createActivity(activity).add(() => {
      this.modal.dismiss();
    });
  }
  // update existing activities
  updateActivity() {
    const activityVal = this.activityForm.value;
    this.activity.activityName = activityVal.activityName;
    this.activity.enrichmentCode = activityVal.enrichmentCode;
    this.activity.updatedDate = new Date();
    console.log('AgencyId:', this.agencyId);
    this.activity.agencyId = this.agencyId;
    this.activityService.updateActivity(this.activity).add(() => {
      this.modal.dismiss();
    });
  }
  // if update - form is populated with current details
  populateActivity = async () => {
    this.activityForm.patchValue(this.activity);
  }

  ngOnInit() {
    console.log(this);
    this.activityForm.valueChanges.subscribe(change => {
      if (this.activityForm.dirty) {
        this.modal.backdropDismiss = false;
      }
    });
    // tslint:disable-next-line: max-line-length
    this.fetchData();
  }
}

