// used for scheduled activity details, schedule activity modal, school details page, staff page, students page
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class SchoolDetailsService {

  public idToken: string;

  constructor(public httpClient: HttpClient, public storage: Storage) { }
  // fetch school data by ID
  getSchoolDetails(id: number) {
    console.log('getSchoolDetails()'); // todo: comment out logging as needed for prod
    return this.loadSchoolDetails(id);
  }

  private loadSchoolDetails(id: number) {
    console.log('loadSchoolDetals() with url: ', environment.schoolsUrl + id);

    return this.httpClient.get(environment.schoolsUrl + id);
  }
  // fetch school's activities
  getSchoolActivitiesByMasterCalendar(id: number, masterCalendarCode: string) {
    return this.loadSchoolActivitiesByMasterCalendar(id, masterCalendarCode).pipe(map(this.processSchoolActivities, this));
  }
  getAgencySchoolScheduledActivitiesByMasterCalendar(schoolId: number, masterCalendarCode: string) {
    return this.loadAgencySchoolScheduledActivitiesByMasterCalendar(schoolId, masterCalendarCode).pipe(map(this.processSchoolActivities, this));
  }
  getAttendanceRetroSummary(schoolId) {
    return this.loadAttendanceRetroSummary(schoolId);
  }

  getErc() {
    return this.loadErc();
  }

  getSchoolActivities(id: number) {
    return this.loadSchoolActivities(id).pipe(map(this.processSchoolActivities, this));
  }
  getSchoolScheduledActivities(id: number) {
    return this.loadSchoolScheduledActivities(id).pipe(map(this.processSchoolActivities, this));
  }
  getSchoolScheduledActivitiesByMasterCalendar(activityId: number,schoolId: number, masterCalendarCode: string) {
    return this.loadSchoolScheduledActivitiesByMasterCalendar(activityId, schoolId, masterCalendarCode).pipe(map(this.processSchoolActivities, this));
  }

  // fetch school's scheduled activities
  private loadSchoolScheduledActivitiesByMasterCalendar(activityId: number,schoolId: number, masterCalendarCode: string) {
    this.storage.get('idToken').then(res => {
      this.idToken = res;
    });
    // const header = new HttpHeaders().set(
    //   'Authorization',
    //   'Bearer ' + this.idToken
    // );
    return this.httpClient.get(environment.activitiesUrl + 'scheduledactivities/school/' + schoolId + '/activity/' + activityId + '/mastercalendar/' + masterCalendarCode);
  }
  // fetch school's scheduled activities
  private loadSchoolScheduledActivities(id: number) {
    this.storage.get('idToken').then(res => {
      this.idToken = res;
    });
    // const header = new HttpHeaders().set(
    //   'Authorization',
    //   'Bearer ' + this.idToken
    // );
    return this.httpClient.get(environment.activitiesUrl + 'scheduledactivities/agency/' + this.profile.agency + '/school/' + id);
  }

  private loadAgencySchoolScheduledActivitiesByMasterCalendar(schoolId: number, masterCalendarCode: string) {
    this.storage.get('idToken').then(res => {
      this.idToken = res;
    });

    const profile = JSON.parse(window.localStorage.getItem('_ionicstorage/_ionickv/userSubject'));
    return this.httpClient.get(environment.activitiesUrl + 'scheduledactivities/agency/' + profile.agency + '/school/' + schoolId + '/mastercalendar/' + masterCalendarCode);

  }

  private loadSchoolActivitiesByMasterCalendar(id: number, masterCalendarCode: string) {
    this.storage.get('idToken').then(res => {
      this.idToken = res;
    });

    const profile = JSON.parse(window.localStorage.getItem('_ionicstorage/_ionickv/userSubject'));
    const adminRoles: number[] = [6,7,8];

    if (adminRoles.includes(profile.roles[0])) {
      console.log('loadActivities() with url: ', environment.activitiesUrl + 'agency/' + profile.agency + '/school/' + id);
      return this.httpClient.get(environment.activitiesUrl + 'agency/' + profile.agency + '/school/' + id + '/mastercalendar/' + masterCalendarCode);
    } else {
      console.log('loadActivities() with url: ', environment.activitiesUrl + 'location/' + id);
      return this.httpClient.get(environment.activitiesUrl + 'location/' + id + '/mastercalendar/' + masterCalendarCode);
    }
  }

  private loadAttendanceRetroSummary(schoolId) {
    const profile = JSON.parse(window.localStorage.getItem('_ionicstorage/_ionickv/userSubject'));
    const agencyRoles: number[] = [6,7,8];

    if(agencyRoles.includes(profile.roles[0])){
      return this.httpClient.get(environment.studentsUrl + 'attendanceretro' + '/agency/' + profile.agency + '/school/' + schoolId);
    }
    else{
      return this.httpClient.get(environment.studentsUrl + 'attendanceretro');
    }
  }

  private loadErc() {
    return this.httpClient.get(environment.studentsUrl + 'attendancecode/erc' );
  }

  private loadSchoolActivities(id: number) {
    this.storage.get('idToken').then(res => {
      this.idToken = res;
    });

    const profile = JSON.parse(window.localStorage.getItem('_ionicstorage/_ionickv/userSubject'));
    const adminRoles: number[] = [6,7,8];

    if (adminRoles.includes(profile.roles[0])) {
      console.log('loadActivities() with url: ', environment.activitiesUrl + 'agency/' + profile.agency + '/school/' + id);
      return this.httpClient.get(environment.activitiesUrl + 'agency/' + profile.agency + '/school/' + id);
    } else {
      console.log('loadActivities() with url: ', environment.activitiesUrl + 'location/' + id);
      return this.httpClient.get(environment.activitiesUrl + 'location/' + id);
    }
  }

  processSchoolActivities(data: any) {
    return data;
  }

  // fetch school's awards
  getSchoolAwards(id: number, agencyId: number) {
    return this.loadSchoolAwards(id, agencyId).pipe(map(this.processSchoolAwards, this));
  }
  private loadSchoolAwards(id: number, agencyId: number) {
    this.storage.get('idToken').then(res => {
      this.idToken = res;
    });

    return this.httpClient.get(environment.awardsUrl + 'location/' + id + '/agency/' + agencyId);
  }
  processSchoolAwards(data: any) {
    return data;
  }

  get profile() {
    return JSON.parse(window.localStorage.getItem('_ionicstorage/_ionickv/userSubject'));
  }

}
